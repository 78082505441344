import React from "react";

import "./VendorSignupMain.scss";

import ManOutlinedIcon from "@mui/icons-material/ManOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";

import {useNavigate} from "react-router-dom" 
import { Helmet } from "react-helmet";

const VendorSignupMain = () => {
    // Navigation Hook
    const navigate = useNavigate();
    return (
   <>
   <Helmet>
        <title>Signup Type | CARS'R'US</title>
    </Helmet>
    <div className="vendor-signup-main">
      <h1>Welcome to Cars’R’Us family</h1>
      <div className="vendor-card-section">
        <div className="vendor-selection-card" onClick={()=> navigate("/usersignup")}>
          <ManOutlinedIcon />
          Customer
        </div>
        <div className="vendor-selection-card" onClick={()=> navigate("/dashboard/vender-signup")}>
          <StoreOutlinedIcon />
          Store or Merchant
        </div>
      </div>
    </div>
   </>
  );
};

export default VendorSignupMain;
