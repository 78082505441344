import axios from "axios";
import React, { useState, Fragment } from "react";
import {
  liveSearchImageRoute,
  vendorNotificationsUpdate,
  vendorRequestStatusUpdate,
} from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import CampaignIcon from "@mui/icons-material/Campaign";
import ChatIcon from '@mui/icons-material/Chat';

import { Badge, IconButton } from "@mui/material";
import { useNoti } from "../../context/notification";
import "./NotificationMenu.scss";
import GrayNoData from "../../assets/images/gray-nodata.svg";

const NotificationMenu = () => {
  const [auth] = useAuth();
  const [menu, setMenu] = useState(false);
  const [notifications,_,chatNotification] = useNoti();
  const navigate = useNavigate();
  const handleRequestClick = async (e, current, code, user_id) => {
    e.preventDefault();
    const { data } = await axios.post(vendorRequestStatusUpdate, {
      code,
      vendor_id: auth.user.user_id,
      status: current === "accept" ? 1 : current === "reject" ? 2 : 0,
    });
    if (data.error === false) {
      axios.get(`${vendorNotificationsUpdate}/${code}`);
      if (current === "accept") {
        setMenu(false)
        navigate(`/dashboard/business-chats?id=${user_id}&type=${1}}`);
      } else if (current === "reject") {
        setMenu(false);
      }
    }
  };

  const handleNotificationClick = (code, user_id) => {
    axios.get(`${vendorNotificationsUpdate}/${code}`);
    sessionStorage.setItem("chat_user_id", user_id);
    navigate("/dashboard/business-chats");
  };

  const messageNotificationClick = (id,type) => {
    navigate(`/dashboard/business-chats?id=${id}&type=${type}`)
  }

  const handleClick = (event) => {
    setMenu((prev) => !prev);
  };
  
  return (
    <Fragment>
      {menu === true && (
        <div className="dashboard-wrapper" onClick={handleClick}></div>
      )}
      <div style={{ position: "relative" }}>
        <IconButton onClick={handleClick}>
          <Badge badgeContent={notifications.length} color="primary">
            <NotificationsNoneOutlinedIcon sx={{color:"white"}} />
          </Badge>
        </IconButton>
        {menu && (
          <div className= "notification-menu" >
            {chatNotification?.map((item,i)=> (
              <div key={i} className="notification">
                <div
                  className="notification-icon"
                  onClick={() =>
                    messageNotificationClick(item.sender_id , 1)
                  }
                >
                  <ChatIcon />
                </div>
                <div>
                  <h5
                    onClick={() =>
                      messageNotificationClick( item.sender_id ,1)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    Name: {item.name}
                  </h5>
                  <h5
                    onClick={() =>
                      messageNotificationClick( item.sender_id ,1)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    Message: {item.message.length > 60 ? `${item.message.slice(0,60)}...` : item.message}
                  </h5>
                  <div
                    onClick={() =>
                      messageNotificationClick( item.sender_id ,1)
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      width:"100%"
                    }}
                  >
                    <h6>{item.date}</h6>
                    <h6>{item.time}</h6>
                  </div>
                  
                </div>
                
              </div>
            ))}
            {notifications?.map((item, i) => (
              <div key={i} className="notification">
                <div
                  className="notification-icon"
                  onClick={() =>
                    handleNotificationClick(item.code, item.user_id)
                  }
                >
                  <CampaignIcon />
                </div>
                <div>
                  <h5
                    onClick={() =>
                      handleNotificationClick(item.code, item.user_id)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {item.message}
                  </h5>
                  <div
                    onClick={() =>
                      handleNotificationClick(item.code, item.user_id)
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                  >
                    <h6>{item.date}</h6>
                    <h6>{item.time}</h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingTop: ".2rem",
                    }}
                  >
                    <button
                      className="accept"
                      onClick={(e) =>
                        handleRequestClick(e, "accept", item.code, item.user_id)
                      }
                    >
                      Accept
                    </button>
                    <button
                      className="pending"
                      onClick={(e) =>
                        handleRequestClick(e, "reject", item.code, item.user_id)
                      }
                    >
                      Reject
                    </button>
                  </div>
                </div>
                {item?.image && (
                  <a
                    href={`${liveSearchImageRoute}/${item?.image}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="notification-image">
                      <img
                        src={`${liveSearchImageRoute}/${item?.image}`}
                        alt="Product"
                      />
                    </div>
                  </a>
                )}
              </div>
            ))}
            {(notifications.length === 0 && chatNotification.length === 0)&& (
                <div style={{ display: "flex", justifyContent: "center" ,alignItems:"center" , height:"100%" }}>
                  <img
                    src={GrayNoData}
                    alt="No-Data"
                    width={250}
                    height={250}
                  />
                </div>
              )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default NotificationMenu;
