import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./RightNavigation.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import {userAdslistingRoute} from "../../utils/Routes"
import { useAuth } from "../../context/auth";
import axios from "axios";
import { DeleteNotificationToken } from "../../utils/ApiRoutes";
const RightNavigation = ({ display, setDisplay }) => {
  const [ auth , setAuth]=useAuth()
  const navigate = useNavigate();
  const handleClick = (address) => {
    if (window.innerWidth <= 800) {
      setDisplay(false);
    }
    navigate(address);
  };

  const handleLogout = async () => {
    try {
      const { data } = await axios.post(DeleteNotificationToken, {
        table: "users",
        field: "user_id",
        id: auth.user.user_id,
        column: "web_token",
      });

      if (data.error === false) {
        setAuth({
          user: null,
          userType: 0,
        });
        localStorage.removeItem("auth");
        navigate("/");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // if(!userCode){
    //   navigate("/")
    // }
  }, [])
  

  return (
    <>
      {display && (
        <div className="right-navigation-bar" id="leftbar_user">
          <div className="close-icon" onClick={() => setDisplay(false)}>
            <CloseIcon />
          </div>
          <Link className="Logo" to={"/"}>
            CARS'R'US
          </Link>
          <div className="nav-items">
            <div onClick={() => handleClick("/profile")}>My Profile</div>
            <div onClick={() => handleClick("/my-products")}>My Products</div>
            <div onClick={() => handleClick("/my-bookmarks")}>My Bookmarks</div>
            <div onClick={() => handleClick(userAdslistingRoute)}>My Ads</div>
            <div onClick={() => handleClick("/my-chat")}>Chats</div>
            <div onClick={() => handleClick("/my-search")}>My Searches</div>
            <div onClick={() => handleClick("/edit-profile")}>Account Settings</div>
            <div onClick={() => handleClick("/my-change-password")}>Change Password</div>
            <div onClick={() => handleLogout()}>Sign out</div>
          </div>
        </div>
      )}
    </>
  );
};

export default RightNavigation;
