import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { AiOutlineClose } from "react-icons/ai";
import { BiImage } from "react-icons/bi";
import {
  getProductImagesRoute,
  imagesRoute,
  insertProductImages,
  productImageDeleteRoute,
} from "../../utils/ApiRoutes";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import "./EditImages.scss";
const EditImages = () => {
  const navigate = useNavigate();
  const { id: prodCode } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState(null);
  const [displayImages, setDisplayImages] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxSize: 5 * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      const filesToUpload = acceptedFiles.filter(
        (file) => file.size <= 5 * 1024 * 1024
      );
      if (acceptedFiles.length !== filesToUpload.length) {
        toast.error("Some files are too large.", toastOptions);
      }
      setFiles(
        filesToUpload.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files?.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="file">
        <div className="thumb-inner">
          <img
            src={file.preview}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
            alt="product"
          />
        </div>
        <div className="info">
          <div className="info-right">
            <BiImage />
            <div>
              <p>{file.name}</p>
            </div>
          </div>
          <div
            className="info-left"
            onClick={() => handleCloseClick(file.name)}
          >
            <AiOutlineClose />
          </div>
        </div>
      </div>
    </div>
  ));
  const Existingthumbs = displayImages?.map((file, i) => (
    <div className="thumb" key={file.name}>
      <div className="file">
        <div className="thumb-inner">
          <img src={`${imagesRoute}/${file}`} alt="product" />
        </div>
        <div className="info">
          <div className="info-right">
            <BiImage />
            <div>
              <p>{file}</p>
            </div>
          </div>
          <div className="info-left" onClick={() => handleDeleteClick(i, file)}>
            <AiOutlineClose />
          </div>
        </div>
      </div>
    </div>
  ));

  const handleCloseClick = (name) => {
    const test = files.filter((file) => {
      return file.name !== name;
    });
    setFiles(test);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      if (files === null){
        navigate(-1);
        return
      }
      for (let index = 0; index < files.length; index++) {
        const formData = new FormData();
        formData.append("code", prodCode);
        formData.append("files", files[index]);
        await axios.post(insertProductImages, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getimages();
    // eslint-disable-next-line
  }, []);

  const getimages = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${getProductImagesRoute}/${prodCode}`);

      if (response.data.product[0].images !== null) {
        setDisplayImages(JSON.parse(response.data.product[0].images));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClick = async (index, name) => {
    try {
    
      setDisplayImages(displayImages.filter((_, i) => i !== index));
      await axios.post(productImageDeleteRoute, {
        code: prodCode,
        Image: name,
      });

    } catch (error) {
      console.error(error.message);
    }
    
  };

  return (
    <>
      <div className="center-area">
        {!isLoading && (
          <>
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <div className="dropzone-info">
                <UploadFileIcon />
                <p>Click to upload or drag and drop</p>
                <p>Maximum file size 5 MB</p>
              </div>
            </div>
            <aside className="thumb-container">
              {Existingthumbs}
              {thumbs}
            </aside>
            <div className="btn-section stick-bottom">
              <button onClick={(e) => handleClick(e)}>NEXT</button>
            </div>
          </>
        )}
        {isLoading && (
          <div className="center1">
            <div className="car-loader"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditImages;
