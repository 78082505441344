import React, { useState, useRef, useEffect, useMemo } from "react";

import "./MerchantHomeMainArea.scss";
import "../../pages/VendorImportExportCountry/country-flag.css";
import Rating from "@mui/material/Rating";
import GoogleMapReact from "google-map-react";
import {
  MaintenanceImagesRoute,
  bannerImageRoute,
  brandLogoRoute,
  insertChatMessage,
  profileImageRoute,
  reviewImagesRoute,
  submitReview,
  submitReviewImages,
  userProfilePictureRoute,
  vendorImagesRoute,
} from "../../utils/ApiRoutes";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";
import noImage from "../../assets/images/no-image.png";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { handleScrollToTop } from "../../utils/ReuseFunction";
import Avatar from "../../assets/images/avatar.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { NoProfile } from "../../assets/icons/svg";
import { Pagination as ReviewPagination } from "@mui/material";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";

import moment from "moment";
const servicesType = [
  "",
  "Buying or Selling Cars",
  "Car Service Repair / Maintainers",
  "Parts",
  "Car Pool",
  "Importing or Exporting",
  "Renting",
];

const MerchantHomeMainArea = ({
  vendorDetails,
  services,
  timings,
  images,
  setReviewChange,
  reviews,
  loading,
  ratingDetails,
  currentPage,
  setCurrentPage,
  currentPageVendors,
}) => {
  const inputRef = useRef();
  const mapRef = useRef();
  const navigate = useNavigate();
  const url = Object.values(useParams())[0];
  const [auth] = useAuth();
  const [rating, setRating] = useState(0);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [dsiplayImages, setDsiplayImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [menu, setmenu] = useState(false);
  useEffect(() => {
    handleScrollToTop();
  }, []);

  // const defaultProps = {
  //   center: {
  //     lat:
  //       vendorDetails.latitude !== "null"
  //         ? Number(vendorDetails?.latitude)
  //         : 25.3952,
  //     lng:
  //       vendorDetails.longitude !== "null"
  //         ? Number(vendorDetails?.longitude)
  //         : 68.3638784,
  //   },
  //   zoom: 16,
  // };

  const handleOpen = () => {
    if (auth.user && auth.userType === 2) {
      setOpen(true);
    } else if (auth.user && auth.userType === 1) {
      toast.error("Vendors are currently not allowed to rate other vendors", toastOptions);
    } else {
      toast.error("Please login to Post a review", toastOptions);
    }
  };
  const handleClose = () => setOpen(false);

  const handleImagesChange = (e) => {
    setFiles(e.target.files);
    const temp = Array.from(e.target.files);
    const images = temp?.map((image) => URL.createObjectURL(image));
    setDsiplayImages(images);
  };

  const feedbackValidate = () => {
    if (rating === 0) {
      return false;
    }
    if (description === "") {
      return false;
    }
    return true;
  };
  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setIsButtonDisable(true);
    if (auth.user) {
      try {
        if (feedbackValidate()) {
          const { data } = await axios.post(submitReview, {
            url,
            description,
            rating,
            user_id: auth.user?.user_id,
          });
          if (files.length > 0) {
            for (let index = 0; index < files.length; index++) {
              const fd = new FormData();
              fd.append("image", files[index]);
              fd.append("code", data.code);
              await axios.post(submitReviewImages, fd);
            }
          }
          if (data?.code) {
            setFiles([]);
            setDescription("");
            setRating(0);
            setDsiplayImages([]);
            handleClose();
            setReviewChange((prev) => ++prev);
          }
        } else {
          toast.error("Please Fill Form Completely!", toastOptions);
        }
      } catch (error) {
        toast.error(error, toastOptions);
      }
    }
    setIsButtonDisable(false);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const timming = useMemo(() => {
    // eslint-disable-next-line
    const timming = timings?.map((timing, i) => {
      const element = Object.keys(timing)[0];
      const data = Object.values(timing)[0];
      if (data !== "Close") {
        const time = data.split("-");
        const timeStart = time[0];
        const timeEnd = time[1];
        return (
          <div key={i}>
            <span>{element} : </span>
            <span>{timeStart + " - " + timeEnd}</span>
          </div>
        );
      } else if (data === "Close") {
        return (
          <div key={i}>
            <span>{element} : </span>
            <span>{data}</span>
          </div>
        );
      }
    });

    return timming; // Add this line to return the JSX elements
  }, [timings]);

  const shopOpen = (incomingTime) => {
    const isOpen = timings?.some((timing) => {
      const data = Object.values(timing)[0];
      if (data !== "Close") {
        const time = data.split("-");
        const timeStart = time[0];
        const timeEnd = time[1];
        // Check if incomingTime is within the shop's open hours using Moment.js
        return moment(incomingTime, "HH:mm A").isBetween(
          moment(timeStart, "HH:mm A"),
          moment(timeEnd, "HH:mm A")
        );
      }
      return false;
    });

    return isOpen;
  };

  const handleChatClick = async (e, id, receiver, type, error) => {
    e.preventDefault();
    if (!error) {
      if (auth.user) {
        await axios.post(insertChatMessage, {
          sender_id: auth.user.user_id,
          receiver_id: id,
          message: `Customer ${auth.user.name} have query while visted Your Profile http://${window.location.host}/merchant/${url}`,
          sender: auth.userType === 2 ? "users" : "vendor",
          receiver,
          type,
        });
        auth.userType === 2
          ? navigate(`/my-chat?id=${id}&type=${type}`)
          : navigate(`/dashboard/business-chats?id=${id}&type=${type}`);
      } else {
        toast.error("Please login to chat", toastOptions);
      }
    } else {
      toast.error("You Cannot Chat with yourself", toastOptions);
    }
  };

  const handleMenuClick = () => {
    if (auth.user) {
      setmenu((prev) => !prev);
    } else {
      toast.error("Please login to contact with vendor", toastOptions);
    }
  };

  return (
    <>
      {!loading && (
        <div className="merchant-home-main">
          <div
            className="merchant-background"
            style={{
              background: vendorDetails?.banner
                ? `url(${bannerImageRoute}/${vendorDetails?.banner}) no-repeat `
                : "",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="merchant-card-wrapper">
              <div className="merchant-card">
                <div className="left-side">
                  <div
                    className="merchant-profile"
                    style={{ padding: vendorDetails?.profile ? "0px" : "2rem" }}
                  >
                    {vendorDetails?.profile && (
                      <img
                        src={
                          vendorDetails?.profile
                            ? `${profileImageRoute}/${vendorDetails?.profile}`
                            : ""
                        }
                        alt=""
                      />
                    )}
                    {!vendorDetails?.profile && <NoProfile />}
                  </div>
                  {!auth.user && (
                    <div className="button-section">
                      <button onClick={() => navigate("/usersignup")}>
                        Sign up with Email
                      </button>
                    </div>
                  )}
                  <div className="get-location-button">
                    Location & Hours{" "}
                    <a
                      href={`http://maps.google.co.uk/maps?q=${vendorDetails.latitude},${vendorDetails?.longitude}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button>Get directions</button>
                    </a>
                  </div>
                  <div className="map">
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API }}
                      defaultCenter={{
                        lat: parseFloat(vendorDetails?.latitude)
                          ? parseFloat(vendorDetails?.latitude)
                          : 25.3952,
                        lng: parseFloat(vendorDetails?.longitude)
                          ? parseFloat(vendorDetails?.longitude)
                          : 68.3638784,
                      }}
                      defaultZoom={15}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map }) => {
                        mapRef.current = map;
                      }}
                    >
                      <div
                        key={vendorDetails.url}
                        lat={
                          parseFloat(vendorDetails.latitude)
                            ? parseFloat(vendorDetails?.latitude)
                            : 25.3952
                        }
                        lng={
                          parseFloat(vendorDetails.longitude)
                            ? parseFloat(vendorDetails?.longitude)
                            : 68.3638784
                        }
                        className="pin"
                      >
                        <a
                          href={`http://maps.google.co.uk/maps?q=${vendorDetails.latitude},${vendorDetails?.longitude}`}
                          target="_blank"
                          rel="noreferrer"
                          className={"pin-card"}
                        >
                          <img
                            src={
                              vendorDetails?.profile === null
                                ? noImage
                                : `${profileImageRoute}/${vendorDetails?.profile}`
                            }
                            alt="profile"
                          />
                          <p className="pin-text">{vendorDetails.name}</p>
                        </a>
                      </div>
                    </GoogleMapReact>
                  </div>
                  <div className="timings">
                    <h4>Timings</h4>
                    {timming}
                  </div>
                  <div className="reviews-details">
                    <div className="reviews-details-heading">
                      <h4>Custumers reviews</h4>
                      <p>{ratingDetails?.Total_Reviews} global ratings</p>
                      <h6>{Number(vendorDetails?.rating)} out of 5</h6>
                    </div>
                    <div className="review-detail">
                      <div>
                        <Rating name="read-only" value={5} readOnly />
                        <p>{ratingDetails?.five_Percentage.toFixed(2)}%</p>
                      </div>
                      <div>
                        <Rating name="read-only" value={4} readOnly />
                        <p>{ratingDetails?.four_Percentage.toFixed(2)}%</p>
                      </div>
                      <div>
                        <Rating name="read-only" value={3} readOnly />
                        <p>{ratingDetails?.three_Percentage.toFixed(2)}%</p>
                      </div>
                      <div>
                        <Rating name="read-only" value={2} readOnly />
                        <p>{ratingDetails?.two_Percentage.toFixed(2)}%</p>
                      </div>
                      <div>
                        <Rating name="read-only" value={1} readOnly />
                        <p>{ratingDetails?.one_Percentage.toFixed(2)}%</p>
                      </div>
                    </div>
                    <div className="review-button">
                      <button onClick={handleOpen}> Write a review </button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <div className="modal">
                          <div className="modal-inner">
                            <div className="top-bar">
                              <h1>Review</h1>
                              <span onClick={handleClose}>
                                <CloseIcon />
                              </span>
                            </div>
                            <div className="rating">
                              <Rating
                                name="read-only"
                                value={rating}
                                onChange={(event, newValue) => {
                                  setRating(newValue);
                                }}
                              />
                            </div>
                            <div className="description">
                              <textarea
                                name="description"
                                id="description"
                                cols="20"
                                rows="5"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                              ></textarea>
                            </div>
                            <div className="hidden-input">
                              <input
                                type="file"
                                id="review-images"
                                ref={inputRef}
                                hidden
                                onChange={(e) => handleImagesChange(e)}
                                multiple
                                accept="image/*"
                              />
                              <div
                                className="input"
                                onClick={() => {
                                  inputRef.current.click();
                                }}
                              >
                                <span>Choose Photos</span>
                                <span></span>
                              </div>
                            </div>
                            <div className="display-images">
                              {dsiplayImages.length <= 4 &&
                                dsiplayImages?.map((image, i) => (
                                  <div className="inner-div-image" key={i}>
                                    <img src={image} alt="Review" />
                                  </div>
                                ))}
                              {dsiplayImages.length > 4 &&
                                dsiplayImages.slice(0, 4)?.map((image, i) => (
                                  <div className="display-image-inner" key={i}>
                                    <img src={image} alt="Review" />
                                    {i === 3 && (
                                      <div className="count">
                                        <span>+{dsiplayImages.length - 4}</span>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>
                            <div className="btn-section">
                              <button
                                onClick={handleReviewSubmit}
                                disabled={isButtonDisable}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
                <div className="right-side">
                  <div className="merchant-name">{vendorDetails.name}</div>
                  <div className="rating">
                    <Rating
                      name="half-rating-read"
                      value={Number(vendorDetails?.rating)}
                      precision={0.1}
                      readOnly
                    />
                  </div>
                  <div className="tags">
                    <div className="tab">
                      {servicesType[Number(vendorDetails.service)]}
                    </div>
                  </div>
                  <div className="open-timming">
                    {shopOpen(moment().format("h:mm A")) ? (
                      <span style={{ color: "green" }}>Open</span>
                    ) : (
                      <span style={{ color: "red" }}>Close</span>
                    )}
                  </div>
                  <div className="badges">
                    Working Since :{" "}
                    {vendorDetails?.since ? vendorDetails?.since : "----"}
                  </div>
                  <div className="button-options">
                    <button onClick={handleOpen} className="red-button">
                      Leave a Review
                    </button>
                    <button>Add Photo</button>
                    <button>Share</button>
                    <div className="chat-menu">
                      <button onClick={() => handleMenuClick()}>Contact</button>
                      {auth.user && menu && (
                        <div className="menu">
                          <>
                            <button
                              onClick={(e) =>
                                handleChatClick(
                                  e,
                                  vendorDetails.vendor_id,
                                  "vendor",
                                  auth.userType === 2 ? 1 : 2,
                                  false
                                )
                              }
                            >
                              Chat <ChatBubbleOutlineIcon />
                            </button>
                            <button
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  vendorDetails.phone
                                );
                                toast.success("Phone# Copied", toastOptions);
                                setmenu(false);
                              }}
                            >
                              {vendorDetails.phone} <LocalPhoneOutlinedIcon />
                            </button>
                          </>
                        </div>
                      )}
                    </div>
                  </div>
                  {vendorDetails?.description &&
                    vendorDetails?.description !== "null" && (
                      <div className="description">
                        <h3>About the Business</h3>
                        <p> {vendorDetails?.description}</p>
                      </div>
                    )}
                  {images && (
                    <div className="photos-section">
                      <Swiper
                        slidesPerView={2}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                      >
                        {images?.map((image, i) => (
                          <SwiperSlide className="swiper-slide" key={i}>
                            <img src={`${vendorImagesRoute}/${image}`} alt="" />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  )}
                  {services?.BRAND?.length > 0 && <h3>Brands</h3>}
                  <div className="brands-section">
                    {services?.BRAND?.map((brands) =>
                      brands?.map((brand, i) => (
                        <div key={i} className="brand-card">
                          <img
                            src={`${brandLogoRoute}/${brand?.logo}`}
                            alt={brand?.name}
                          />
                          <p>{brand?.name}</p>
                        </div>
                      ))
                    )}
                  </div>
                  {services?.IMPORT?.length > 0 &&
                    services?.EXPORT?.length > 0 && (
                      <div className="import-and-export-section">
                        <div className="imports">
                          <h4>Importing From</h4>
                          <div className="import-country">
                            {services?.IMPORT?.map((imports) =>
                              imports?.map((data, i) => (
                                <div key={i} className="brand-card">
                                  <div
                                    className={`flag flag-${data.country_code.toLowerCase()}`}
                                  ></div>
                                  <p>{data?.name}</p>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                        <div className="exports">
                          <h4>Exporting To</h4>
                          <div className="export-country">
                            {services?.EXPORT?.map((exports) =>
                              exports?.map((data, i) => (
                                <div key={i} className="brand-card">
                                  <div
                                    className={`flag flag-${data.country_code.toLowerCase()}`}
                                  ></div>
                                  <p>{data?.name}</p>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  {services?.MAINTENANCE?.length > 0 && <h3>Service</h3>}
                  <div className="brands-section">
                    {services?.MAINTENANCE?.map((maintenance) =>
                      maintenance?.map((maintain, i) => (
                        <div key={i} className="brand-card">
                          <img
                            src={`${MaintenanceImagesRoute}/${maintain?.image}`}
                            alt={maintain?.name}
                          />
                          <p>{maintain?.name}</p>
                        </div>
                      ))
                    )}
                  </div>
                  <h3>Reviews</h3>
                  <div className="reviews-section">
                    {reviews.length === 0 && <p>No reviews to display</p>}
                    {currentPageVendors?.map((review, i) => (
                      <div className="review" key={i}>
                        <div className="top-section">
                          <h6>
                            <img
                              src={
                                review?.profile !== null
                                  ? `${userProfilePictureRoute}/${review?.profile}`
                                  : Avatar
                              }
                              alt={`${review?.fullname}-profile`}
                            />
                            {review?.fullname}
                          </h6>
                          <Rating
                            value={Number(review?.rating)}
                            readOnly
                          ></Rating>
                        </div>
                        <div className="description">
                          <p style={{ whiteSpace: "pre-line" }}>
                            {review?.description}
                          </p>
                        </div>
                        <div className="review-images">
                          {review?.images
                            ? JSON.parse(review?.images).length <= 4 &&
                              JSON.parse(review?.images).map((image, i) => (
                                <div className="review-image" key={i}>
                                  <img
                                    src={`${reviewImagesRoute}/${image}`}
                                    alt="review"
                                  />
                                </div>
                              ))
                            : null}
                          {review?.images
                            ? JSON.parse(review?.images).length > 4 &&
                              JSON.parse(review.images)
                                .slice(0, 4) // Take only the first four items
                                .map((image, i) => (
                                  <div className="review-image" key={i}>
                                    <img
                                      src={`${reviewImagesRoute}/${image}`}
                                      alt="review"
                                    />
                                    {i === 3 && (
                                      <div className="count">
                                        <span>
                                          +
                                          {JSON.parse(review?.images).length -
                                            4}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ))
                            : null}
                        </div>
                        <div className="bottom-section">
                          <p>{moment(review?.date).format("DD/MM/YYYY")}</p>
                          <p>
                            {moment(review?.time, "HH:mm:ss").format("h:mm A")}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="pagination-center">
                    <ReviewPagination
                      variant="outlined"
                      page={currentPage}
                      onChange={handlePageChange}
                      count={
                        reviews?.length !== 0
                          ? Math.ceil(reviews?.length / 3)
                          : 1
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <ProgressCircle height={"50vh"} />}
    </>
  );
};

export default MerchantHomeMainArea;
