import React, { useState, useEffect  } from "react";

import "./MerchantCard.scss";
import noImage from "../../assets/images/no-image.png";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { insertChatMessage, profileImageRoute } from "../../utils/ApiRoutes";
import { Skeleton } from "primereact/skeleton";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify"
import { toastOptions } from "../../utils/ToastOptions";
const MerchantCard = ({ vendor, badges, badgeId, handleCardClick,background , serviceName}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [auth] = useAuth();

  // const [alert, setAlert] = useState(false);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  const handleChatClick = async (e, id, receiver, type, error) => {
    e.preventDefault();
    if (!error) {
      if (auth.user) {
        await axios.post(insertChatMessage, {
          sender_id: auth.user.user_id,
          receiver_id: id,
          message: `Customer ${auth.user.name} have query for this service ${serviceName}`,
          sender: auth.userType === 2 ? "users" : "vendor",
          receiver,
          type,
        });
        auth.userType === 2
          ? navigate(`/my-chat?id=${id}&type=${type}`)
          : navigate(`/dashboard/business-chats?id=${id}&type=${type}`);
      } else {
        toast.error("Please login to chat", toastOptions);
      }
    } else {
      toast.error("You Cannot Chat with yourself", toastOptions);
    }
  };

  return (
    <>
      {!loading && (
        <div className="new-merchant-card" style={{background : background && background}}>
          <div
            className="left-side"
            onClick={() => handleCardClick(vendor?.url)}
          >
            <div className="merchant-img">
              <img
                src={
                  vendor?.profile === null
                    ? noImage
                    : `${profileImageRoute}/${vendor?.profile}`
                }
                alt="Profile"
              />
            </div>

            {vendor.type === 2 && (
              <div className="merchant-badge">Featured</div>
            )}
            {vendor.type === 3 && <div className="merchant-badge">Premium</div>}
          </div>
          <div className="right-side">
            <h2 onClick={()=> handleCardClick(vendor?.url)}>{vendor?.name}</h2>
            {/* <div className="rating">
          <span>{`${value}.0`}</span>
          <Rating name="read-only" value={value} readOnly style={{color:"blue"}} />
          <span>(7 Reviews)</span>
        </div> */}
            <h6>
              Vision:{" "}
              {vendor?.vision && vendor?.vision !== "null"
                ? vendor?.vision
                : "----"}
            </h6>
            <div className="location">
              <LocationOnOutlinedIcon />
              <span>
                {`${vendor?.location_name} - ${vendor?.city_name} - ${vendor?.country_name}`}
              </span>
            </div>
            <div className="badges">
              {badgeId?.length > 0 && (
                <div>
                  {
                    badges?.filter(
                      (item) =>
                        item.badge_id ===
                        badgeId[Math.floor(Math.random() * badgeId.length)]
                    )[0]?.name
                  }
                </div>
              )}
              {/* <div>
                <VerifiedOutlinedIcon />
                Verified Business
              </div>

              <div>
                <HandshakeOutlinedIcon />
                Trusted Service Provider
              </div> */}
            </div>
            <div className="merchant-desc">
              “
              {vendor?.description && vendor?.description !== "null"
                ? vendor?.description
                : "----"}
              “
            </div>
            <div className="buttons">
              {(vendor.type === 2 || vendor.type === 3) && (
                <div
                  onClick={() => {navigator.clipboard.writeText(vendor.phone);toast.success("Phone Number Copied",toastOptions)}}
                >
                  Phone
                </div>
              )}
              {vendor.type === 3 && auth.userType === 2 && (
                 auth.userType !== 1) &&  ( <div
                  onClick={(e) =>
                    handleChatClick(
                      e,
                      vendor?.vendor_id,
                      auth.userType === 1 ? "users" : "vendor",
                      1,
                      auth.userType === 1 &&
                      vendor.id.toString() === auth.user.user_id.toString() 
                        ? true
                        : false
                    )
                  }
                  style={{cursor:auth.user ? auth.userType === 1 ?    "not-allowed" : "pointer"  : "pointer"}}
                >
                  Chat
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Skeleton
            width="40%"
            height="10rem"
            borderRadius="16px"
            className="mb-2"
          ></Skeleton>
          <div style={{ width: "60%" }}>
            <Skeleton width="96%" height="1rem" className="mb-2"></Skeleton>
            <Skeleton width="76%" height="1rem" className="mb-2"></Skeleton>
            <Skeleton width="66%" height="1rem" className="mb-2"></Skeleton>
            <Skeleton width="96%" height="1rem" className="mb-2"></Skeleton>
            <div style={{ display: "flex", gap: "1rem" }}>
              <Skeleton
                width="40%"
                height="2.5rem"
                borderRadius="16px"
                className="mb-2"
              ></Skeleton>
              <Skeleton
                width="40%"
                height="2.5rem"
                borderRadius="16px"
                className="mb-2"
              ></Skeleton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MerchantCard;
