// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {  getToken, onMessage } from "firebase/messaging";
import {  getMessaging } from "firebase/messaging/sw";

import axios from "axios";
import { userTokenUpdate, vendorTokenUpdate } from "../utils/ApiRoutes";

const firebaseConfig = {
  apiKey: "AIzaSyD_sfJCok_95m9wFET3nnigoIVZZnuZvIU",
  authDomain: "cars-r-us-382918.firebaseapp.com",
  projectId: "cars-r-us-382918",
  storageBucket: "cars-r-us-382918.appspot.com",
  messagingSenderId: "410308174553",
  appId: "1:410308174553:web:2b46f62312f3da37f9d4c8",
  measurementId: "G-GZ8J0KPMBD",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const onMessageListener = () => {
  onMessage(messaging, (payload) => {
    console.log(payload.data);

    // if (payload.notification) {
    //   console.log(payload.notification.title);
    //   console.log(payload.notification.body);
    // }
    // if (payload.data) {
    //   console.log(payload.data);
    // }
  });
};

const vapidKey =
  "BCkAFwB7qLa6uzJ9mVR33C_ySLWZWTSIEkG4vaH-eCyOIWZwt0YYXyPpNjU46UReEyGxJ8BRR50aGEBGSYnUQbs";

export const Sendrequest = async (id, type) => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return getToken(messaging, { vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            if (type === 2) {
              axios
                .post(userTokenUpdate, {
                  user_id: id,
                  token: currentToken,
                  from: "web_token",
                })
                .then((res) =>{});
            } else if (type === 1) {
              axios
                .post(vendorTokenUpdate, {
                  vendor_id: id,
                  token: currentToken,
                  from: "web_token",
                })
                .then((res) => {});
            }
          } else {
            console.log("Failed to generate the registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};
