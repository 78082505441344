import React, { useState, useEffect, useRef } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import "../../styledComponents/Styled.scss";
import { useNavigate } from "react-router-dom";

const UserProductEditDropdown = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClick = (id, operation) => {
    toggleDropdown();
    if (operation === "edit") {
        navigate(`/edit-car/${id}`);
    } else if (operation === "amenities") {
        navigate(`/car-amenities/${id}`);
    } else if (operation === "images") {
        navigate(`/car-images/${id}`);
    } 
  };

  return (
    <div className={`dropdown ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <MoreHorizIcon />
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          <li onClick={() => handleClick(id, "edit")}>Edit</li>
          <li onClick={() => handleClick(id, "amenities")}>Amenities</li>
          <li onClick={() => handleClick(id, "images")}>Images</li>
        </ul>
      )}
    </div>
  );
};

export default UserProductEditDropdown;
