import React, { useState, useEffect, useRef } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import "./Styled.scss";
import { useNavigate } from "react-router-dom";
const DropdownMenu = ({ id, setActive, deactivate, ads }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClick = (id, operation) => {
    toggleDropdown();
    localStorage.setItem("prodCode", id);
    if (operation === "edit") {
      setActive("editcar");
    } else if (operation === "amenities") {
      setActive("caramenities");
    } else if (operation === "deactive") {
      deactivate(id);
    } else if (operation === "images") {
      setActive("carimages");
    } else if (operation === "promote") {
      navigate("/dashboard/business-promotion/recommended");
    }
  };

  return (
    <div className={`dropdown ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <MoreHorizIcon />
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          <li onClick={() => handleClick(id, "edit")}>Edit</li>
          <li onClick={() => handleClick(id, "amenities")}>Amenities</li>
          <li onClick={() => handleClick(id, "deactive")}>De-Active</li>
          <li onClick={() => handleClick(id, "images")}>Images</li>
          {ads.findIndex((item) => item?.code?.toString() === id?.toString()) !==
          -1 ? (
            <li style={{cursor:"not-allowed"}}>Promoted</li>
          ) : (
            <li onClick={() => handleClick(id, "promote")}>Promote</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default DropdownMenu;
