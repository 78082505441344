import React, {
  useState,
  useRef,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../context/auth";
import Dubizzle from "../../assets/images/Dubizzle.png";
import "./EditProduct.scss";
import { dataContext } from "../../App.js";
import axios from "axios";
import {
  GetModelRoute,
  cardRoute,
  editProductRoute,
  getCitiesRoute,
  getlocationRoute,
  updateProductRoute,
} from "../../utils/ApiRoutes";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { addCarFormValidate } from "../../utils/ReuseFunction";

// import a from "../IndividualUserSignup/index.js"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AllColors = [
  "",
  { id: 1, name: "Unlisted" },
  { id: 2, name: "White" },
  { id: 3, name: "Silver" },
  { id: 4, name: "Black" },
  { id: 5, name: "Grey" },
  { id: 6, name: "Blue" },
  { id: 7, name: "Green" },
  { id: 8, name: "Red" },
  { id: 9, name: "Gold" },
  { id: 10, name: "Maroon" },
  { id: 11, name: "Beige" },
  { id: 12, name: "Pink" },
  { id: 13, name: "Brown" },
  { id: 14, name: "Burgundy" },
  { id: 15, name: "Yellow" },
  { id: 16, name: "Bronze" },
  { id: 17, name: "Purple" },
  { id: 18, name: "Turquoise" },
  { id: 19, name: "Orange" },
  { id: 20, name: "indigo" },
  { id: 21, name: "Magenta" },
  { id: 22, name: "Navy" },
];
const EditProduct = () => {
  const imageInputRef = useRef();
  const navigate = useNavigate();
  const { countries, brands } = useContext(dataContext);
  const [auth] = useAuth();
  const country = useMemo(
    () =>
      countries.find(
        (country) => country.name.toLowerCase() === "united arab emirates"
      ),
    [countries]
  );
  const { id: prodCode } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [DisplayImage, setDisplayImage] = useState("");
  const [image, setImage] = useState("");
  const [models, setModels] = useState([]);
  const [cities, setCities] = useState([]);
  const [color, setColor] = useState("");
  const [locations, setLocations] = useState([]);
  const [has_image, setHas_image] = useState(0);
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    modelYear: "",
    price: "",
    phone: auth.user ? auth.user.user_phone : "",
    country_id: country.country_id,
    city: "",
    location: "",
    mileage: "",
    color: "",
    insured: "",
    door: "",
    unlisted_color: null,
    type: "",
  });
  const handleColorChange = (event) => {
    const selectedColor = event.target.value;
    setColor(selectedColor);
  };
  const handleInputChange = async (event) => {
    const { name, value, type } = event.target;
    if (type === "number") {
      if (!isNaN(value) && parseFloat(value) >= 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (name === "brand") {
      const response = await axios.get(`${GetModelRoute}/${value}`);
      setModels(response.data.Model);
      document.getElementById("model").removeAttribute("disabled");
    }
    if (name === "city") {
      const response = await axios.get(`${getlocationRoute}/${value}`);
      setLocations(response.data.location);
      document.getElementById("location").removeAttribute("disabled");
    }
  };

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${editProductRoute}/${prodCode}`);
      const {
        brand_id,
        model_id,
        model_year,
        price,
        phone,
        city_id,
        location_id,
        milage,
        country_id,
        color_id,
        insured,
        door_id,
        card,
        unllisted_color,
        type,
      } = response.data.product[0];
      setImage(card);
      const cityResponse = await axios.get(`${getCitiesRoute}/${country_id}`);
      const modelresponse = await axios.get(`${GetModelRoute}/${brand_id}`);
      const locationresponse = await axios.get(
        `${getlocationRoute}/${city_id}`
      );
      setLocations(locationresponse.data.location);
      setModels(modelresponse.data.Model);
      setCities(cityResponse.data.city);
      setFormData({
        brand: brand_id,
        model: model_id,
        modelYear: model_year,
        price: price,
        phone: phone,
        city: city_id,
        location: location_id,
        mileage: milage,
        color: color_id,
        insured: insured,
        door: door_id,
        country_id: country_id,
        unllisted_color: unllisted_color,
        type: type,
      });
      const id = AllColors.filter(
        (currentColor) => currentColor.id === Number(color_id)
      );
      if (id.length !== 0) {
        setColor(id[0].name);
      }
      const { data } = await axios.get(
        `${getCitiesRoute}/${country?.country_id}`
      );
      setCities(data.city);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [country?.country_id, prodCode]);
  useEffect(() => {
    getData();
  }, [getData]);
  const date = new Date();
  const years = [];
  for (let index = date.getFullYear() + 1; index > 1950; index--) {
    years.push(index);
  }

  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (addCarFormValidate(formData, image)) {
      const {
        brand,
        model,
        modelYear,
        price,
        phone,
        city,
        mileage,
        color,
        door,
        location,
        insured,
        country_id,
        unlisted_color,
        type,
      } = formData;

      const fd = new FormData();
      fd.append("card", image);
      fd.append("brand_id", brand);
      fd.append("model_id", model);
      fd.append("model_year", modelYear);
      fd.append("price", price);
      fd.append("phone", phone);
      fd.append("city_id", city);
      fd.append("location_id", location);
      fd.append("milage", mileage);
      fd.append("color_id", color);
      fd.append("door_id", door);
      fd.append("insured", insured);
      fd.append("user_code", auth.user.code);
      fd.append("country_id", country_id);
      fd.append("unlisted_color", unlisted_color);
      fd.append("type", type);
      fd.append("has_image" ,has_image)
      fd.append("code", prodCode);
      try {
        const response = await axios.post(updateProductRoute, fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.error === false) {
          navigate(-1);
        }
      } catch (error) {
        console.error(error);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="center-area">
        {!isLoading && (
          <>
            <h1>
              CAR’R’US will buy your car at a fair market price if you need to
              sell it urgently.
            </h1>
            <div className="form-section">
              <div className="form-left">
                <div className="signup-note">
                  If you looking to upload multiple Vehicle{" "}
                  <Link to={"/dashboard/vender-signup"}>sign-up</Link>
                </div>
                <h4>
                  Auto-fill your car infromation by entering your VIN / Chassis
                  number
                </h4>
                <img src={Dubizzle} alt="" />
                <input type="text" placeholder="Enter VIN/ Chasis Number" />
                {DisplayImage !== "" && (
                  <div className="card-image-section">
                    <div className="card-image">
                      <img src={DisplayImage} alt="Product" />
                    </div>
                  </div>
                )}
                {DisplayImage === "" && (
                  <div className="card-image-section">
                    <div className="card-image">
                      <img src={`${cardRoute}/${image}`} alt="" />
                    </div>
                  </div>
                )}
              </div>
              <div className="or-part">OR</div>
              <div className="form-right">
                <form>
                  <div>
                    <select
                      name="brand"
                      id="brand"
                      value={formData.brand}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Brand</option>
                      {brands.map((brand) => (
                        <option key={brand.brand_id} value={brand.brand_id}>
                          {brand.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <select
                      name="model"
                      id="model"
                      value={formData.model}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Model</option>
                      {models.map((model) => (
                        <option key={model.model_id} value={model.model_id}>
                          {model.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <select
                      name="modelYear"
                      id="modelYear"
                      onChange={handleInputChange}
                      value={formData.modelYear}
                    >
                      <option value="0">Select Model Year</option>
                      {years.map((year) => (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <input
                      type="number"
                      id="price"
                      placeholder="20000 AED"
                      name="price"
                      value={formData.price}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <input
                      type="number"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Phone"
                    />
                  </div>
                  <div>
                    <select
                      name="city"
                      id="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    >
                      <option value="">Select City</option>
                      {cities?.map((city) => (
                        <option key={city.city_id} value={city.city_id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <select
                      name="location"
                      id="location"
                      value={formData.location}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Location</option>
                      {locations.map((location) => (
                        <option
                          key={location.location_id}
                          value={location.location_id}
                        >
                          {location.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <input
                      type="number"
                      name="mileage"
                      id="mileage"
                      value={formData.mileage}
                      onChange={handleInputChange}
                      placeholder="Mileage"
                    />
                  </div>
                  <div>
                    <Select
                      value={color}
                      onChange={handleColorChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Select Color</em>;
                        }
                        return selected;
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                      className="color-menu"
                      placeholder="Select a color"
                    >
                      <MenuItem value="0" disabled>
                        Select Color
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="White"
                      >
                        White{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "white",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Silver"
                      >
                        Silver{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "silver",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Black"
                      >
                        Black{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "black",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Grey"
                      >
                        Grey{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "Grey",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Blue"
                      >
                        Blue{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "blue",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Green"
                      >
                        Green{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "green",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Red"
                      >
                        Red{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "red",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Gold"
                      >
                        Gold{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "gold",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Maroon"
                      >
                        Maroon{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "maroon",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Beige"
                      >
                        Beige{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "beige",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Pink"
                      >
                        Pink{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "pink",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Brown"
                      >
                        Brown{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "brown",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Burgundy"
                      >
                        Burgundy{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "#800020",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Yellow"
                      >
                        Yellow{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "yellow",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Bronze"
                      >
                        Bronze{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "#CD7F32",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Purple"
                      >
                        Purple{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "purple",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Turquoise"
                      >
                        Turquoise{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "turquoise",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Orange"
                      >
                        Orange{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "orange",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Indigo"
                      >
                        Indigo{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "indigo",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Magenta"
                      >
                        Magenta
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "magenta",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Navy"
                      >
                        Navy{" "}
                        <div
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "navy",
                          }}
                        />
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        value="Unlisted"
                      >
                        Unlisted
                      </MenuItem>
                    </Select>
                  </div>

                  {formData.color === 1 && (
                    <div>
                      <>
                        <input
                          type="text"
                          id="unlisted_color"
                          name="unlisted_color"
                          value={formData.unlisted_color}
                          onChange={handleInputChange}
                          placeholder="Unlisted Color"
                        />
                      </>
                    </div>
                  )}
                  <div>
                    <select
                      name="door"
                      id="door"
                      value={formData.door}
                      onChange={handleInputChange}
                    >
                      <option value="0">Select Doors</option>
                      <option value="1">2 Doors</option>
                      <option value="2">3 Doors</option>
                      <option value="3">4 Doors</option>
                      <option value="4">5 Doors</option>
                      <option value="5">6 Doors</option>
                    </select>
                  </div>
                  <div>
                    <select
                      name="insured"
                      id="insured"
                      value={formData.insured}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="0">Select Insured</option>
                      <option value="1">yes</option>
                      <option value="2">No</option>
                    </select>
                  </div>
                  <div>
                    <select
                      name="type"
                      id="type"
                      value={formData.type}
                      onChange={(e) => handleInputChange(e)}
                    >
                      <option value="0">Used/New</option>
                      <option value="1">New</option>
                      <option value="2">Used</option>
                    </select>
                  </div>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    ref={imageInputRef}
                    onChange={(e) => {
                      setImage(e.target.files[0]);
                      setHas_image(1);
                      setDisplayImage(URL.createObjectURL(e.target.files[0]));
                    }}
                    accept="image/*"
                    hidden
                  />
                  <div>
                    <div className="input">
                      <span
                        className="tab"
                        onClick={() => imageInputRef.current.click()}
                      >
                        Click to add image
                      </span>
                      <span className="file-name">
                        {image !== "" ? image?.name : ""}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="btn-section">
              <button onClick={handleClick}>Submit</button>
            </div>
          </>
        )}
        {isLoading && (
          <div className="center1">
            <div className="car-loader"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditProduct;
