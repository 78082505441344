import React, { useState, useEffect } from "react";
import "./AddCarsMainArea.scss";
import { CarsOutline, Clicks, Cost } from "../../assets/icons/svg";
import AddIcon from "@mui/icons-material/Add";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

import axios from "axios";
import {
  cardRoute,
  getProductRoute,
  getVendorAds,
  updateProductStatus,
} from "../../utils/ApiRoutes";
import DropdownMenu from "../../styledComponents/Dropdown";
import Car from "../../assets/images/car.svg";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import moment from "moment";
import { Image } from 'primereact/image';

const AddCarsMainArea = ({ setActive }) => {
  const [expanded, setExpanded] = useState(true);
  const [search, setSearch] = useState("")
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([])
  const [ads, setAds] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [auth]=useAuth()

  const handleChange = (panel) => (isExpanded) => {
    if (expanded === "panel1") {
      setExpanded(false);
    } else {
      setExpanded(panel);
    }
  };

  const handleHighlightClick = (e) => {
    const button = e.target;
    button.classList.contains("active")
      ? button.classList.remove("active")
      : button.classList.add("active");
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line 
  }, []);

  const getProduct = async () => {
    setIsloading(true);
    try {
      const product = await axios.get(`${getProductRoute}/${auth.user?.code}`);
      const response = await axios.post(getVendorAds, {
        make_by: 1,
        maker_id: auth.user?.user_id,
      });
      setAds(response.data.Ads);
      setProducts(product.data.product);
      setAllProducts(product.data.product);
    } catch (error) {
      toast.error("Somthing went wrong", toastOptions);
    }
    setIsloading(false)
  };

  const deactivate = async (code) => {
    await axios.post(updateProductStatus, {
      code,
    });
    getProduct();
  };

  const handleSearchChange =  (e) => {
    const {value} = e.target
    setSearch(value)
    let data = [];
    allProducts.forEach(element => {
      const brand = element?.brand_name?.toLowerCase()
      const model = element?.model_name?.toLowerCase()
      if (brand && model) {
        if (brand?.includes(value?.toLowerCase()) || model?.includes(value?.toLowerCase())) {
          data.push(element)
        }
      }
    })
    setProducts(data);
  }

  return (
    <>
      {!isloading && (
        <div className="add-cars-main">
          <div className="states-area">
            <div className="states-card">
              <div className="card-image">
                <CarsOutline />
              </div>
              <div className="card-content">
                <h4>Number of Cars</h4>
                <p>{products.length}</p>
              </div>
            </div>
            
            <div className="states-card">
              <div className="card-image">
                <Clicks />
              </div>
              <div className="card-content">
                <h4>Number of Clicks</h4>
                <p>{products.reduce((prev,curr) => prev + curr.views , 0 )}</p>
              </div>
            </div>
            <div className="states-card">
              <div className="card-image">
                <Cost />
              </div>
              <div className="card-content">
                <h4>Cost</h4>
                <p>
                {products.reduce((prev,curr) => prev + curr.price , 0 )} <span>$</span>
                </p>
              </div>
            </div>
          </div>
          <div className="add-cars-section">
            <h1>Add Cars</h1>
            <div onClick={() => setActive("addcar")}>
              <AddIcon />
            </div>
          </div>
          <div className="main-section">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h4>Cars {products.length}</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="table-data">
                  <div className="header-row">
                    <div className="search-icon">
                      <SearchIcon />
                    </div>
                    <div className="search-input">
                      <input type="text" placeholder="Search" value={search} onChange={handleSearchChange} />
                    </div>
                    {/* <div className="heading">Views</div> */}
                    <div className="heading">Click</div>
                    <div className="heading">Review Date</div>
                    <div className="heading">Status</div>
                    <div className="heading">Option</div>
                  </div>
                  {products.map((product, i) => (
                    <div className="row" key={i}>
                      <div className="checkbox">
                        <input type="checkbox" />
                      </div>
                      <div className="product">
                        <div className="image">
                          {product.card && (
                            <Image src={`${cardRoute}/${product.card}`}  alt={""} preview width="250" />
                          )}
                          {!product.card && <img src={Car} alt="Product" />}
                        </div>
                        <div className="details">
                          <h4>
                            {product.brand_name}, {product.model_name}
                          </h4>
                          <p>
                            {product.model_year}, {product.milage}KM
                          </p>
                        </div>
                      </div>
                      {/* <div className="views">0</div> */}
                      <div className="clicks">{product.views}</div>
                      <div className="review-date">{moment(product.date).format("DD/MM/YYYY")}</div>
                      <div className="highlight">
                        <button onClick={(e) => handleHighlightClick(e)}>
                          {product.status === 0 ? "Active" : "De-Active"}
                        </button>
                      </div>
                      <div className="options">
                        <DropdownMenu
                          id={product.code}
                          setActive={setActive}
                          deactivate={deactivate}
                          ads={ads}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <ToastContainer />
        </div>
      )}
      {isloading && <ProgressCircle height={"calc(100vh - 150px)"} />}
    </>
  );
};

export default AddCarsMainArea;
