import React ,{useRef, useState}from "react";
import DashboardLeftbar from "../components/DashboardLeftbar/DashboardLeftbar";
import DashboardTopbar from "../components/DashboardTopbar/DashboardTopbar";
import "./LayoutStyle.scss";
import { useEffect } from "react";
import axios from "axios";
import { vendorNotificationsRoute ,getVendorDetails} from "../utils/ApiRoutes";
import { useAuth } from "../context/auth";

const DashboardLayout = ({ children }) => {
  
  const timeoutRef = useRef(null);
  const [Name, setName] = useState("")
  const [notification, setNotification ] = useState([])
  const [leftbar, setLeftbar] = useState(window.innerWidth<900 ?false:true)

  const [auth] = useAuth()

  const  handleMenuClick = () => {
    setLeftbar((prev) => !prev)
  }

  useEffect(() => {
    timeoutRef.current = setInterval(function () {
      getData();
    }, 5000);
    
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line
  }, []);
  
  const getData = async() => {
    const {data} = await axios.get(`${vendorNotificationsRoute}/${auth.user.code}`)
    if (data.error ===false) {
      setNotification(data.notification);
    }
  }
  
  return (
    <div className="flex background-light min-h-screen">
      <div>
        <DashboardLeftbar leftbar={leftbar}/>
      </div>
      <div className="width-md" style={{paddingBottom :"5rem"}}>
        <DashboardTopbar
        Topbar notification={notification} handleMenuClick={handleMenuClick}/>
        <h2 style={{marginLeft:"1rem"}}>Business name : {auth?.user?.bussinessName}</h2>
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
