import React, { useEffect, useState, useCallback, useMemo } from "react";
import "./SingleProductMainArea.scss";
import SpecificationComponent from "../SpecifictionComponent/SpecificationComponent";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import axios from "axios";
import {
  imagesRoute,
  insertChatMessage,
  productHistoryManage,
  productRoute,
  profileImageRoute,
  userProfilePictureRoute,
  videosRoute,
} from "../../utils/ApiRoutes";
import { useNavigate, useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DummyImage from "../../assets/images/no-image.png";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  fuelTypes,
  Colors,
  Warrenty,
  Doors,
  Cylinders,
  TransmissionType,
  BodyType,
  Horspower,
  SteeringSide,
  Trim,
  RegionalSpec,
} from "../../assets/data";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import { cardRoute } from "../../utils/ApiRoutes";
import Modal from "@mui/material/Modal";
import { useAuth } from "../../context/auth";
import Admin from "../../assets/images/download.png";

SwiperCore.use([Autoplay]);
const SingleProductMainArea = () => {
  const url = Object.values(useParams())[0];
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [productDetails, setproductDetails] = useState({});
  const [imagesArr, setImagesArr] = useState([]);
  const [open, setOpen] = useState(false);

  const controller = useMemo(() => new AbortController(), []);

  const historySave = useCallback(async () => {
    if (auth.user) {
      try {
        const { data } = await axios.post(productHistoryManage, {
          url,
          user_id: auth.user.user_id,
        });

        if (data.error === false) {
          return;
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
      return;
    }
  }, [auth.user, url]);

  useEffect(() => {
    async function getProduct() {
      const response = await axios.get(`${productRoute}/${url}`, {
        signal: controller.signal,
      });
      setproductDetails(response.data.Product?.[0] ?? {});
      const imagesArr = response.data.Product?.[0]?.images
        ? JSON.parse(response.data.Product[0].images)
        : [];
      setImagesArr(imagesArr);
    }
    getProduct();
    historySave();

    return () => controller.abort();
    // eslint-disable-next-line
  }, []);

  const decodedDescription = decodeURIComponent(
    productDetails.description || ""
  );
  const handleChatClick = async (e, id, receiver, type, error) => {
    e.preventDefault();
    if (!error) {
      if (auth.user) {
        await axios.post(insertChatMessage, {
          sender_id: auth.user.user_id,
          receiver_id: id,
          message: `Customer ${auth.user.name} have query for this product http://${window.location.host}/product/${url}`,
          sender: auth.userType === 2 ? "users" : "vendor",
          receiver,
          type,
        });

        await axios.get(`${productRoute}/${url}/Chat/Enter`);
        auth.userType === 2
          ? navigate(`/my-chat?id=${id}&type=${type}`)
          : navigate(`/dashboard/business-chats?id=${id}&type=${type}`);
      } else {
        toast.error("Please login to chat", toastOptions);
      }
    } else {
      toast.error("You Cannot Chat with yourself", toastOptions);
    }
  };

  const phoneClick = (e) => {
    e.preventDefault();
    if (auth.user) {
      setOpen(true);
    } else {
      toast.error("Please Login to get Phone Number", toastOptions);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <div className="center">
      
      <div className="single-product">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="modal-phone">
            <div className="modal-inner">
              <div className="top-bar">
                <h1>Phone</h1>
                <span onClick={handleClose}>
                  <CloseIcon />
                </span>
              </div>
              <div className="phone-section">
                <input type="text" value={productDetails?.phone} />
                <button
                  onClick={() => {
                    navigator.clipboard.writeText(productDetails.phone);
                    toast.success("Phone# Copied Successfully", toastOptions);
                  }}
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        </Modal>
        <div className="single-product-left">
          <div className="product-images">
            <Swiper
              slidesPerView={1}
              modules={[Pagination]}
              loop={true}
              pagination={true}
            >
              <SwiperSlide>
                <img src={`${cardRoute}/${productDetails.card}`} alt="" />
              </SwiperSlide>
              {imagesArr?.map((element, i) => (
                <SwiperSlide key={i}>
                  {element.split(".")[1] === "jpg" ||
                  element.split(".")[1] === "jpeg" ||
                  element.split(".")[1] === "png" ? (
                    <img src={`${imagesRoute}/${element}`} alt="" />
                  ) : element.split(".")[1] === "mp4" ? (
                    <video
                      src={`${videosRoute}/${element}`}
                      muted
                      controls
                    ></video>
                  ) : (
                    <img src={DummyImage} alt="" />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="product-details">
            <div className="product-specifications">
              <SpecificationComponent
                text={
                  productDetails.fuel_type === null ||
                  productDetails.fuel_type === ""
                    ? "Fuel Type"
                    : `Fuel Type : ${
                        fuelTypes[Number(productDetails.fuel_type)]
                      }`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.body_condition === null ||
                  productDetails.body_condition === ""
                    ? "Body condition"
                    : `Body condition : ${productDetails.body_condition} / 10`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.mechanical_condition === null ||
                  productDetails.mechanical_condition === ""
                    ? "Mechanical condition"
                    : `Mechanical condition : ${productDetails.mechanical_condition} / 10`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.color_id === null ||
                  productDetails.color_id === ""
                    ? "Color"
                    : `Color : ${Colors[Number(productDetails.color_id)]}`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.warranty === null ||
                  productDetails.warranty === ""
                    ? "Warranty"
                    : `Warranty : ${Warrenty[Number(productDetails.warranty)]}`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.door_id === null ||
                  productDetails.door_id === ""
                    ? "Doors"
                    : `${Doors[Number(productDetails.door_id)]}`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.cylinders === null ||
                  productDetails.cylinders === ""
                    ? "No. of Cylinders"
                    : `No. of Cylinders : ${
                        Cylinders[Number(productDetails.cylinders)]
                      }`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.transmission_type === null ||
                  productDetails.transmission_type === ""
                    ? "Transmission Type"
                    : `Transmission Type : ${
                        TransmissionType[
                          Number(productDetails.transmission_type)
                        ]
                      }`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.body_type === null ||
                  productDetails.body_type === ""
                    ? "Body Type"
                    : `Body Type : ${
                        BodyType[Number(productDetails.body_type)]
                      }`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.horsepower === null ||
                  productDetails.horsepower === ""
                    ? "Horsepower"
                    : `Horsepower : ${
                        Horspower[Number(productDetails.horsepower)]
                      }`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.steering_side === null ||
                  productDetails.steering_side === ""
                    ? "Steering Side"
                    : `Steering Side : ${
                        SteeringSide[Number(productDetails.steering_side)]
                      }`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.trim === null || productDetails.trim === ""
                    ? "Trim"
                    : `Trim : ${Trim[Number(productDetails.trim)]}`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.regional_spec === null ||
                  productDetails.regional_spec === ""
                    ? "Regional Spec"
                    : `${RegionalSpec[Number(productDetails.regional_spec)]}`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.model_year === null ||
                  productDetails.model_year === ""
                    ? "Year"
                    : `Year : ${productDetails.model_year}`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.milage === null || productDetails.milage === ""
                    ? "Kilometers"
                    : `Kilometers : ${productDetails.milage}`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.insured === null ||
                  productDetails.insured === ""
                    ? "Is car is insured in UAE ?"
                    : `Is car is insured in UAE ?${
                        productDetails.insured === 1 ? "Yes" : "No"
                      }`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.price === null || productDetails.price === ""
                    ? "Price"
                    : `Price : ${productDetails.price} AED`
                }
              />
              <SpecificationComponent
                text={
                  productDetails.phone === null || productDetails.phone === ""
                    ? "Phone"
                    : `Phone : ${productDetails.phone}`
                }
              />
            </div>
            <div className="product-detail">
              <h1>Description</h1>
              <div
                className="product-desc"
                dangerouslySetInnerHTML={{ __html: decodedDescription }}
              ></div>
              <h1>Features</h1>
              <div className="product-features">
                <span>
                  {productDetails.abs === 0 ? <CloseIcon /> : <CheckIcon />}ABS
                </span>
                <span>
                  {productDetails.alloy_rims === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Alloy Rims
                </span>
                <span>
                  {productDetails.cassette_player === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Cassette Player
                </span>
                <span>
                  {productDetails.climate_control === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Climate Control
                </span>
                <span>
                  {productDetails.front_camera === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Front Camera
                </span>
                <span>
                  {productDetails.keyless_entry === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Keyless Entry
                </span>
                <span>
                  {productDetails.power_mirrors === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Power Mirrors
                </span>
                <span>
                  {productDetails.rear_seat_entertainment === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Rear Seat Entertainment
                </span>
                <span>
                  {productDetails.rear_camera === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Rear Camera
                </span>
                <span>
                  {productDetails.usb_and_auxillary_cable === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  USB and Auxillary Cable
                </span>
                <span>
                  {productDetails.air_bags === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Air Bags
                </span>
                <span>
                  {productDetails.AM_FM_Radio === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  AM FM Radio
                </span>
                <span>
                  {productDetails.cool_box === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  cool_box
                </span>
                <span>
                  {productDetails.dvd_player === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  DVD Player
                </span>
                <span>
                  {productDetails.heated_seats === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Heated Seats
                </span>
                <span>
                  {productDetails.navigation_system === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Navigation System
                </span>
                <span>
                  {productDetails.power_steering === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Power Steering
                </span>
                <span>
                  {productDetails.rear_ac_vents === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Rear AC Vents
                </span>
                <span>
                  {productDetails.sun_roof === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Sun Roof
                </span>
                <span>
                  {productDetails.air_conditioning === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Air Conditioning
                </span>
                <span>
                  {productDetails.ocd_player === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  OCD Player
                </span>
                <span>
                  {productDetails.cruise_control === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Cruise Control
                </span>
                <span>
                  {productDetails.front_speakers === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Front Speakers
                </span>
                <span>
                  {productDetails.immobilizer_key === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Immobilizer Key
                </span>
                <span>
                  {productDetails.power_locks === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Power Locks
                </span>
                <span>
                  {productDetails.power_windows === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Power Windows
                </span>
                <span>
                  {productDetails.rear_speakers === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Rear Speakers
                </span>
                <span>
                  {productDetails.steering_switches === 0 ? (
                    <CloseIcon />
                  ) : (
                    <CheckIcon />
                  )}
                  Steering Switches
                </span>
              </div>
              <h1>Verification</h1>
              <div className="product-verification"></div>
            </div>
          </div>
        </div>
        <div className="single-product-right">
          <div className="seller-details">
            <div className="seller-profile">
              <img
                src={
                  productDetails.uploaded_by === 1
                    ? productDetails.business_profile !== null
                      ? `${profileImageRoute}/${productDetails.business_profile}`
                      : Admin
                    : productDetails.uploaded_by === 2
                    ? productDetails.user_image !== null
                      ? `${userProfilePictureRoute}/${productDetails.user_image}`
                      : Admin
                    : Admin
                }
                alt=""
              />
            </div>
            <div className="seller-detail">
              <h5>
                {productDetails.uploaded_by === 1
                  ? productDetails.vendor_name
                  : productDetails.uploaded_by === 0
                  ? productDetails.admin_name
                  : productDetails.uploaded_by === 2
                  ? productDetails.user_name
                  : ""}
              </h5>
              <p>
                {productDetails.location_name} - {productDetails.city_name} -
                {productDetails.country_name}
              </p>
            </div>
          </div>
          <div className="product-details">
            <h4>Price : {productDetails.price} AED</h4>
            <h2>
              {productDetails.brand_name} {productDetails.model_name}
            </h2>
            <div className="product-condition">
              <span>
                <CalendarMonthRoundedIcon /> {productDetails.model_year}
              </span>
              <span>
                <SpeedOutlinedIcon /> {productDetails.milage}KM
              </span>
            </div>
          </div>
          {/* <div className="blank-card"></div> */}
          <div className="chat-section">
           { (productDetails.uploaded_by !== 2 && auth.userType !== 1) &&  ( <button
              onClick={(e) =>
                handleChatClick(
                  e,
                  productDetails?.uploader_id,
                  productDetails.uploaded_by === 2 ? "users" : "vendor",
                  auth.userType === 2 && productDetails.uploaded_by === 2
                    ? 0
                    : auth.userType === 1 && productDetails.uploaded_by === 1
                    ? 2
                    : 1,
                  auth.userType === 2 &&
                    productDetails.uploaded_by === 2 &&
                    auth.user.user_id === productDetails?.uploader_id
                    ? true
                    : auth.userType === 1 &&
                      productDetails.uploaded_by === 1 &&
                      auth.user.user_id === productDetails?.uploader_id
                    ? true
                    : false
                )
              }
              style={{cursor:auth.user ? auth.userType === 2 ? productDetails.uploaded_by === 2 ?   "not-allowed" : "pointer" : "not-allowed" : "pointer"}}
            >
              Chat
            </button>)}
            <button onClick={(e) => phoneClick(e)}>Show Phone Number</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProductMainArea;
