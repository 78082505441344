import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import "./IndividualUserSignup.scss";
import Dubizzle from "../../assets/images/Dubizzle.png";
import { toastOptions } from "../../utils/ToastOptions";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IoCarSportSharp } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { BiImage } from "react-icons/bi";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import { useNavigate } from "react-router-dom";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { addCarFormValidate } from "../../utils/ReuseFunction";

// import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  GetModelRoute,
  addCarRoute,
  getCitiesRoute,
  getlocationRoute,
  insertProductImages,
  updateAmenitiesRoute,
} from "../../utils/ApiRoutes";
import axios from "axios";
import { RegionalSpec } from "../../assets/data";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
import { useContext } from "react";
import { dataContext } from "../../App";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AllColors = [
  "",
  { id: 1, name: "Unlisted" },
  { id: 2, name: "White" },
  { id: 3, name: "Silver" },
  { id: 4, name: "Black" },
  { id: 5, name: "Grey" },
  { id: 6, name: "Blue" },
  { id: 7, name: "Green" },
  { id: 8, name: "Red" },
  { id: 9, name: "Gold" },
  { id: 10, name: "Maroon" },
  { id: 11, name: "Beige" },
  { id: 12, name: "Pink" },
  { id: 13, name: "Brown" },
  { id: 14, name: "Burgundy" },
  { id: 15, name: "Yellow" },
  { id: 16, name: "Bronze" },
  { id: 17, name: "Purple" },
  { id: 18, name: "Turquoise" },
  { id: 19, name: "Orange" },
  { id: 20, name: "indigo" },
  { id: 21, name: "Magenta" },
  { id: 22, name: "Navy" },
];

const initialState = {
  amenity_id: null,
  product_id: null,
  fuel_type: "",
  body_condition: "",
  mechanical_condition: "",
  warranty: "",
  cylinders: "",
  transmission_type: "",
  body_type: "",
  horsepower: "",
  steering_side: "",
  trim: "",
  regional_spec: "",
  description: "",
  abs: 0,
  alloy_rims: 0,
  cassette_player: 0,
  climate_control: 0,
  front_camera: 0,
  keyless_entry: 0,
  power_mirrors: 0,
  rear_seat_entertainment: 0,
  rear_camera: 0,
  usb_and_auxillary_cable: 0,
  air_bags: 0,
  AM_FM_Radio: 0,
  cool_box: 0,
  dvd_player: 0,
  heated_seats: 0,
  navigation_system: 0,
  power_steering: 0,
  rear_ac_vents: 0,
  sun_roof: 0,
  air_conditioning: 0,
  ocd_player: 0,
  cruise_control: 0,
  front_speakers: 0,
  immobilizer_key: 0,
  power_locks: 0,
  power_windows: 0,
  rear_speakers: 0,
  steering_switches: 0,
  code: "",
};

const IndividualUserSignup = () => {
  const imageInputRef = useRef();
  const navigate = useNavigate();
  const { countries, brands } = useContext(dataContext);
  const country = useMemo(
    () =>
      countries.find(
        (country) => country.name.toLowerCase() === "united arab emirates"
      ),
    [countries]
  );
  const [auth] = useAuth();
  const [DisplayImage, setDisplayImage] = useState("");
  const [prodCode, setProdCode] = useState("");
  const [image, setImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [models, setModels] = useState([]);
  const [locations, setLocations] = useState([]);
  const [color, setColor] = useState("");
  const [value, setValue] = useState("");
  const [formData, setFormData] = useState({
    brand: "",
    model: "",
    modelYear: "",
    price: "",
    phone: auth.user ? auth.user.user_phone : "",
    country_id: country.country_id,
    city: "",
    location: "",
    mileage: "",
    color: "",
    insured: "",
    door: "",
    unlisted_color: null,
    type: "",
  });
  useEffect(() => {
    if (!auth.user) {
      navigate("/usersignup");
    }
    // eslint-disable-next-line
  }, []);

  // Step 1
  const handleInputChange = async (event) => {
    const { name, value , type } = event.target;
    if (type === "number") {
      if (!isNaN(value) && parseFloat(value) >= 0) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
    if (name === "brand") {
      const response = await axios.get(`${GetModelRoute}/${value}`);
      setModels(response.data.Model);
      document.getElementById("model").removeAttribute("disabled");
    }
    if (name === "city") {
      const response = await axios.get(`${getlocationRoute}/${value}`);
      setLocations(response.data.location);
      document.getElementById("location").removeAttribute("disabled");
    }
  };

  useEffect(() => {
    const id = AllColors.filter((currentColor) => currentColor.name === color);
    if (id.length !== 0) {
      setFormData({ ...formData, color: id[0].id });
    }
    // eslint-disable-next-line
  }, [color]);

  const getData = useCallback(async () => {
    try {
      const { data } = await axios.get(
        `${getCitiesRoute}/${country.country_id}`
      );
      setCities(data.city);
    } catch (error) {
      console.error(error.message);
    }
  }, [country.country_id]);

  useEffect(() => {
    getData();
  }, [getData]);

  const date = new Date();
  const years = [];
  for (let index = date.getFullYear() + 1; index > 1950; index--) {
    years.push(index);
  }

  const handleColorChange = (event) => {
    const selectedColor = event.target.value;
    setColor(selectedColor);
  };

  const [step, setStep] = useState(0);
  // Car details form state
  const [amenities, setAmenities] = useState(initialState);

  const [files, setFiles] = useState([]);
  // handle changes for the secific type of states
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setAmenities({ ...amenities, [name]: value });
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setAmenities({ ...amenities, [name]: checked ? 1 : 0 });
  };

  // Handle next Click
  const handleClick = async (e, next) => {
    e.preventDefault();
    setIsLoading(true);
    if (next === 1) {
      setStep(next);
    } else if (next === 2) {
      if (addCarFormValidate(formData, image)) {
        const {
          brand,
          model,
          modelYear,
          price,
          phone,
          city,
          mileage,
          color,
          door,
          location,
          insured,
          country_id,
          unlisted_color,
          type,
        } = formData;

        const fd = new FormData();
        fd.append("card", image);
        fd.append("brand_id", brand);
        fd.append("model_id", model);
        fd.append("model_year", modelYear);
        fd.append("price", price);
        fd.append("phone", phone);
        fd.append("city_id", city);
        fd.append("location_id", location);
        fd.append("milage", mileage);
        fd.append("color_id", color);
        fd.append("door_id", door);
        fd.append("insured", insured);
        fd.append("user_code", auth.user.code);
        fd.append("country_id", country_id);
        fd.append("unlisted_color", unlisted_color);
        fd.append("uploaded_by", 2);
        fd.append("purpose", 0);
        fd.append("type", type);
        try {
          const response = await axios.post(addCarRoute, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data.error === false) {
            setProdCode(response.data.code);
            setStep(next);
          } else {
            console.log("something went Wrong");
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else if (next === 3) {
      for (let index = 0; index < files.length; index++) {
        const formData = new FormData();
        formData.append("code", prodCode);
        formData.append("files", files[index]);
        try {
          const response = await axios.post(insertProductImages, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data.error === false) {
          }
        } catch (e) {
          console.log(e);
        }
      }
      setStep(next);
    }
    setIsLoading(false);
  };
  // handle Back Click
  const handleBackClick = (e) => {
    e.preventDefault();
    setStep((prev) => prev - 1);
  };

  const validateForm = (amenities) => {
    const data = {
      fuel_type: "",
      body_condition: "",
      mechanical_condition: "",
      warranty: "",
      cylinders: "",
      transmission_type: "",
      body_type: "",
      horsepower: "",
      steering_side: "",
      trim: "",
      regional_spec: "",
    };
    const filterData = Object.keys(data).filter(key => amenities[key] === "" )
    if (filterData.length === 0 ) {
      return true
    }else{
      return false
    }
  };

  const handleFinishClick = async (e) => {
    e.preventDefault();
    setIsLoading(false);
    if (validateForm(amenities)) {
      const response = await axios.post(updateAmenitiesRoute, {
        ...amenities,
        description: value,
        code: prodCode,
      });
      if (response.data.error === false) {
        localStorage.setItem("prodCode", prodCode);
        navigate("/my-products");
      }
    }else {
      toast.error("All Fields are required" ,toastOptions)
      console.log("All Fields are required");
    }
    setIsLoading(false);
  };
  // Funtion for removing the image
  const handleCloseClick = (name) => {
    const test = files.filter((file) => {
      return file.name !== name;
    });
    setFiles(test);
  };
  const handleDeleteClick = () => {
    setDisplayImage("");
    setImage("");
  };
  //Creating of Dropzone
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxSize: 5 * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      const filesToUpload = acceptedFiles.filter(
        (file) => file.size <= 5 * 1024 * 1024
      );
      if (acceptedFiles.length !== filesToUpload.length) {
        toast.error("Some files are too large.", toastOptions);
      }
      setFiles(
        filesToUpload.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  //showing the list of the dropped images
  const thumbs = files.map((file) => (
    <div className="thumb" key={file.name}>
      <div className="file">
        <div className="thumb-inner">
          <img
            src={file.preview}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
            alt="product"
          />
        </div>
        <div className="info">
          <div className="info-right">
            <BiImage />
            <div>
              <p>{file.name}</p>
              <h6>({(file.size / 1024 / 1024).toFixed(2)} MB)</h6>
            </div>
          </div>
          <div
            className="info-left"
            onClick={() => handleCloseClick(file.name)}
          >
            <AiOutlineClose />
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Helmet>
        <title>
          {step === 0
            ? "Sell Your Car"
            : step === 1
            ? "Car Details"
            : step === 2
            ? "Car Images"
            : "Car Amenities"}{" "}
          | CARS'R'US
        </title>
      </Helmet>
      <div className="individual-signup">
        <Link className="brand" to={"/"}>
          CARS'R'US
        </Link>
        <Stepper
          activeStep={step}
          alternativeLabel
          className="individual-stepper"
        >
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
        <div className="content">
          {step === 0 && (
            <div className="center-area">
              <h1>
                CAR’R’US will buy your car from you at a fair market price if
                you want to sell urgently,
              </h1>
              <div className="card" onClick={(e) => handleClick(e, 1)}>
                <IoCarSportSharp />
              </div>
            </div>
          )}
          {step === 1 && (
            <div className="center-area">
              {!isLoading && (
                <>
                  <h1>
                    CAR’R’US will buy your car at a fair market price if you
                    need to sell it urgently.
                  </h1>
                  <div className="form-section">
                    <div className="form-left">
                      <div className="signup-note">
                        If you looking to upload multiple Vehicle{" "}
                        <Link to={"/dashboard/vender-signup"}>sign-up</Link>
                      </div>
                      <h4>
                        Auto-fill your car infromation by entering your VIN /
                        Chassis number
                      </h4>
                      <img src={Dubizzle} alt="" />
                      <input
                        type="text"
                        placeholder="Enter VIN/ Chasis Number"
                      />
                      {DisplayImage !== "" && (
                        <div className="card-image-section">
                          <div className="card-image">
                            <img src={DisplayImage} alt="Product" />
                            <div
                              className="delete-btn"
                              onClick={handleDeleteClick}
                            >
                              <DeleteOutlineIcon />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="or-part">OR</div>
                    <div className="form-right">
                      <form>
                        <div>
                          <select
                            name="brand"
                            id="brand"
                            value={formData.brand}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Brand</option>
                            {brands.map((brand) => (
                              <option
                                key={brand.brand_id}
                                value={brand.brand_id}
                              >
                                {brand.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <select
                            name="model"
                            id="model"
                            value={formData.model}
                            onChange={handleInputChange}
                          >
                            <option value="">Select Model</option>
                            {models.map((model) => (
                              <option
                                key={model.model_id}
                                value={model.model_id}
                              >
                                {model.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <select
                            name="modelYear"
                            id="modelYear"
                            onChange={handleInputChange}
                            value={formData.modelYear}
                          >
                            <option value="0">Select Model Year</option>
                            {years.map((year) => (
                              <option value={year} key={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <input
                            type="number"
                            id="price"
                            placeholder="20000 AED"
                            name="price"
                            value={formData.price}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div>
                          <input
                            type="number"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            placeholder="Phone"
                          />
                        </div>
                        <div>
                          <select
                            name="city"
                            id="city"
                            value={formData.city}
                            onChange={handleInputChange}
                          >
                            <option value="">Select City</option>
                            {cities?.map((city) => (
                              <option key={city.city_id} value={city.city_id}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <select
                            name="location"
                            id="location"
                            value={formData.location}
                            onChange={handleInputChange}
                            disabled
                          >
                            <option value="">Select Location</option>
                            {locations.map((location) => (
                              <option
                                key={location.location_id}
                                value={location.location_id}
                              >
                                {location.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <input
                            type="number"
                            name="mileage"
                            id="mileage"
                            value={formData.mileage}
                            onChange={handleInputChange}
                            placeholder="Mileage"
                          />
                        </div>
                        <div>
                          <Select
                            value={color}
                            onChange={handleColorChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>Select Color</em>;
                              }
                              return selected;
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                            className="color-menu"
                            placeholder="Select a color"
                          >
                            <MenuItem value="0" disabled>
                              Select Color
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="White"
                            >
                              White{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "white",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Silver"
                            >
                              Silver{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "silver",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Black"
                            >
                              Black{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "black",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Grey"
                            >
                              Grey{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "Grey",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Blue"
                            >
                              Blue{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "blue",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Green"
                            >
                              Green{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "green",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Red"
                            >
                              Red{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Gold"
                            >
                              Gold{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "gold",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Maroon"
                            >
                              Maroon{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "maroon",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Beige"
                            >
                              Beige{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "beige",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Pink"
                            >
                              Pink{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "pink",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Brown"
                            >
                              Brown{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "brown",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Burgundy"
                            >
                              Burgundy{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#800020",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Yellow"
                            >
                              Yellow{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "yellow",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Bronze"
                            >
                              Bronze{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "#CD7F32",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Purple"
                            >
                              Purple{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "purple",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Turquoise"
                            >
                              Turquoise{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "turquoise",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Orange"
                            >
                              Orange{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "orange",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Indigo"
                            >
                              Indigo{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "indigo",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Magenta"
                            >
                              Magenta
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "magenta",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Navy"
                            >
                              Navy{" "}
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "50%",
                                  backgroundColor: "navy",
                                }}
                              />
                            </MenuItem>
                            <MenuItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              value="Unlisted"
                            >
                              Unlisted
                            </MenuItem>
                          </Select>
                        </div>

                        {formData.color === 1 && (
                          <div>
                            <>
                              <input
                                type="text"
                                id="unlisted_color"
                                name="unlisted_color"
                                value={formData.unlisted_color}
                                onChange={handleInputChange}
                                placeholder="Unlisted Color"
                              />
                            </>
                          </div>
                        )}
                        <div>
                          <select
                            name="door"
                            id="door"
                            value={formData.door}
                            onChange={handleInputChange}
                          >
                            <option value="0">Select Doors</option>
                            <option value="1">2 Doors</option>
                            <option value="2">3 Doors</option>
                            <option value="3">4 Doors</option>
                            <option value="4">5 Doors</option>
                            <option value="5">6 Doors</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="insured"
                            id="insured"
                            value={formData.insured}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value="0">Select Insured</option>
                            <option value="1">yes</option>
                            <option value="2">No</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="type"
                            id="type"
                            value={formData.type}
                            onChange={(e) => handleInputChange(e)}
                          >
                            <option value="0">Used/New</option>
                            <option value="1">New</option>
                            <option value="2">Used</option>
                          </select>
                        </div>
                        <input
                          type="file"
                          name="file"
                          id="file"
                          ref={imageInputRef}
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                            setDisplayImage(
                              URL.createObjectURL(e.target.files[0])
                            );
                          }}
                          accept="image/*"
                          hidden
                        />
                        <div>
                          <div className="input">
                            <span
                              className="tab"
                              onClick={() => imageInputRef.current.click()}
                            >
                              Click to add image
                            </span>
                            <span className="file-name">
                              {image !== "" ? image?.name : ""}
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="btn-section">
                    <button onClick={(e) => handleBackClick(e)}>BACk</button>
                    <button onClick={(e) => handleClick(e, 2)}>NEXT</button>
                  </div>
                </>
              )}
              {isLoading && (
                <div className="center1">
                  <div className="car-loader"></div>
                </div>
              )}
            </div>
          )}
          {step === 2 && (
            <div className="center-area">
              {!isLoading && (
                <>
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="dropzone-info">
                      <UploadFileIcon />
                      <p>Click to upload or drag and drop</p>
                      <p>Maximum file size 5 MB</p>
                    </div>
                  </div>
                  <aside className="thumb-container">{thumbs}</aside>
                  <div className="btn-section stick-bottom">
                    <button onClick={(e) => handleBackClick(e)}>BACk</button>
                    <button onClick={(e) => handleClick(e, 3)}>NEXT</button>
                  </div>
                </>
              )}
              {isLoading && (
                <div className="center1">
                  <div className="car-loader"></div>
                </div>
              )}
            </div>
          )}
          {step === -1 && <div className="center-area"></div>}
          {step === 3 && (
            <div className="center-area">
              {!isLoading && (
                <>
                  <h1>Car Details Page</h1>
                  <div className="car-detail-form">
                    <form>
                      <div className="inputs">
                        <div>
                          <select
                            name="fuel_type"
                            id="fuel_type"
                            value={amenities.fuel_type}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Fuel Type</option>
                            <option value="1">Petrol</option>
                            <option value="2">Diesel</option>
                            <option value="3">Electric</option>
                            <option value="4">Hybrid</option>
                            <option value="5">
                              LPG (Liquefied Petroleum Gas)
                            </option>
                            <option value="6">
                              CNG (Compressed Natural Gas)
                            </option>
                            <option value="7">Hydrogen</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="body_condition"
                            id="body_condition"
                            value={amenities.body_condition}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Body Condtion</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="mechanical_condition"
                            id="mechanical_condition"
                            value={amenities.mechanical_condition}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">
                              Select Mechanical Condition
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        </div>

                        <div>
                          <select
                            name="warranty"
                            id="warranty"
                            value={amenities.warranty}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Warranty</option>
                            <option value="1">Yes</option>
                            <option value="2">No</option>
                          </select>
                        </div>

                        <div>
                          <select
                            name="cylinders"
                            id="cylinders"
                            value={amenities.cylinders}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Cylinders</option>
                            <option value="1">4 Cylinders</option>
                            <option value="2">6 Cylinders</option>
                            <option value="3">8 Cylinders</option>
                            <option value="4">Other</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="transmission_type"
                            id="transmission_type"
                            value={amenities.transmission_type}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Transmission Type</option>
                            <option value="1">Manual</option>
                            <option value="2">Automatic</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="body_type"
                            id="body_type"
                            value={amenities.body_type}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Body Type</option>
                            <option value="1">Sedan</option>
                            <option value="2">Coupe</option>
                            <option value="3">Hatchback</option>
                            <option value="4">Station Wagon</option>
                            <option value="5">
                              SUV (Sport Utility Vehicle)
                            </option>
                            <option value="6">Crossover</option>
                            <option value="7">Convertible</option>
                            <option value="8">Roadster</option>
                            <option value="9">Pick-up Truck</option>
                            <option value="10">Van</option>
                            <option value="11">
                              MPV (Multi-Purpose Vehicle)
                            </option>
                            <option value="12">Limousine</option>
                            <option value="13">Supercar</option>
                            <option value="14">Electric Vehicle (EV)</option>
                            <option value="15">Hybrid Car</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="horsepower"
                            id="horsepower"
                            value={amenities.horsepower}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Horspower</option>
                            <option value="1">Less than 100 HP</option>
                            <option value="2">100 - 200 HP</option>
                            <option value="3">200 - 300 HP</option>
                            <option value="4">300 - 400 HP</option>
                            <option value="5">400 - 500 HP</option>
                            <option value="6">500 - 600 HP</option>
                            <option value="7">600+ HP</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="steering_side"
                            id="steering_side"
                            value={amenities.steering_side}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Steering Side</option>
                            <option value="1">Left</option>
                            <option value="2">Right</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="trim"
                            id="trim"
                            value={amenities.trim}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0">Select Trim</option>
                            <option value="1">Base</option>
                            <option value="2">Premium</option>
                            <option value="3">Sport</option>
                            <option value="4">Luxury</option>
                            <option value="5">Limited</option>
                          </select>
                        </div>
                        <div>
                          <select
                            name="regional_spec"
                            id="regional_spec"
                            value={amenities.regional_spec}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            <option value="0"> Select regional Spec</option>
                            {RegionalSpec?.map((data, i) =>
                              data === "" ? null : (
                                <option value={i} key={i}>
                                  {data}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="rich-text-editor"></div>
                      <h1>Features</h1>
                      <div className="checkboxes">
                        <div>
                          <input
                            type="checkbox"
                            name="abs"
                            id="abs"
                            onChange={(e) => handleCheckboxChange(e)}
                            checked={amenities.abs === 0 ? false : true}
                          />
                          <label htmlFor="abs">ABS</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="alloy_rims"
                            onChange={(e) => handleCheckboxChange(e)}
                            id="alloy_rims"
                            checked={amenities.alloy_rims === 0 ? false : true}
                          />
                          <label htmlFor="alloy_rims">Alloy Rims</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="cassette_player"
                            onChange={(e) => handleCheckboxChange(e)}
                            id="cassette_player"
                            checked={
                              amenities.cassette_player === 0 ? false : true
                            }
                          />
                          <label htmlFor="cassette_player">
                            Cassette Player
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="climate_control"
                            onChange={(e) => handleCheckboxChange(e)}
                            id="climate_control"
                            checked={
                              amenities.climate_control === 0 ? false : true
                            }
                          />
                          <label htmlFor="climate_control">
                            Climate Control
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="front_camera"
                            onChange={(e) => handleCheckboxChange(e)}
                            id="front_camera"
                            checked={
                              amenities.front_camera === 0 ? false : true
                            }
                          />
                          <label htmlFor="front_camera">Front Camera</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="keyless_entry"
                            onChange={(e) => handleCheckboxChange(e)}
                            id="keyless_entry"
                            checked={
                              amenities.keyless_entry === 0 ? false : true
                            }
                          />
                          <label htmlFor="keyless_entry">Keyless Entry</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="power_mirrors"
                            onChange={(e) => handleCheckboxChange(e)}
                            id="power_mirrors"
                            checked={
                              amenities.power_mirrors === 0 ? false : true
                            }
                          />
                          <label htmlFor="power_mirrors">Power Mirrors</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="rear_seat_entertainment"
                            onChange={(e) => handleCheckboxChange(e)}
                            id="rear_seat_entertainment"
                            checked={
                              amenities.rear_seat_entertainment === 0
                                ? false
                                : true
                            }
                          />
                          <label htmlFor="rear_seat_entertainment">
                            Rear Seat Entertainment
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            name="rear_camera"
                            onChange={(e) => handleCheckboxChange(e)}
                            id="rear_camera"
                            checked={amenities.rear_camera === 0 ? false : true}
                          />
                          <label htmlFor="rear_camera">Rear Camera</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="usb_and_auxillary_cable"
                            id="usb_and_auxillary_cable"
                            checked={
                              amenities.usb_and_auxillary_cable === 0
                                ? false
                                : true
                            }
                          />
                          <label htmlFor="usb_and_auxillary_cable">
                            USB and Auxillary Cable
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="air_bags"
                            id="air_bags"
                            checked={amenities.air_bags === 0 ? false : true}
                          />
                          <label htmlFor="air_bags">Air Bags</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="AM_FM_Radio"
                            id="AM_FM_Radio"
                            checked={amenities.AM_FM_Radio === 0 ? false : true}
                          />
                          <label htmlFor="AM_FM_Radio">AM/FM Radio</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="cool_box"
                            id="cool_box"
                            checked={amenities.cool_box === 0 ? false : true}
                          />
                          <label htmlFor="cool_box">Cool Box</label>
                        </div>
                        <div>
                          <input
                            onChange={(e) => handleCheckboxChange(e)}
                            type="checkbox"
                            name="dvd_player"
                            id="dvd_player"
                            checked={amenities.dvd_player === 0 ? false : true}
                          />
                          <label htmlFor="dvd_player">DVD Player</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="heated_seats"
                            id="heated_seats"
                            checked={
                              amenities.heated_seats === 0 ? false : true
                            }
                          />
                          <label htmlFor="heated_seats">Heated Seats</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="navigation_system"
                            id="navigation_system"
                            checked={
                              amenities.navigation_system === 0 ? false : true
                            }
                          />
                          <label htmlFor="navigation_system">
                            Navigation System
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="power_steering"
                            id="power_steering"
                            checked={
                              amenities.power_steering === 0 ? false : true
                            }
                          />
                          <label htmlFor="power_steering">Power Steering</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="rear_ac_vents"
                            id="rear_ac_vents"
                            checked={
                              amenities.rear_ac_vents === 0 ? false : true
                            }
                          />
                          <label htmlFor="rear_ac_vents">Rear AC Vents</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="sun_roof"
                            id="sun_roof"
                            checked={amenities.sun_roof === 0 ? false : true}
                          />
                          <label htmlFor="sun_roof">Sun Roof</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="air_conditioning"
                            id="air_conditioning"
                            checked={
                              amenities.air_conditioning === 0 ? false : true
                            }
                          />
                          <label htmlFor="air_conditioning">
                            Air Conditioning
                          </label>
                        </div>
                        <div>
                          <input
                            onChange={(e) => handleCheckboxChange(e)}
                            type="checkbox"
                            name="ocd_player"
                            id="ocd_player"
                            checked={amenities.ocd_player === 0 ? false : true}
                          />
                          <label htmlFor="ocd_player"> OCD Player</label>
                        </div>
                        <div>
                          <input
                            onChange={(e) => handleCheckboxChange(e)}
                            type="checkbox"
                            name="cruise_control"
                            id="cruise_control"
                            checked={
                              amenities.cruise_control === 0 ? false : true
                            }
                          />
                          <label htmlFor="cruise_control">Cruise Control</label>
                        </div>
                        <div>
                          <input
                            onChange={(e) => handleCheckboxChange(e)}
                            type="checkbox"
                            name="front_speakers"
                            id="front_speakers"
                            checked={
                              amenities.front_speakers === 0 ? false : true
                            }
                          />
                          <label htmlFor="front_speakers">Front Speakers</label>
                        </div>
                        <div>
                          <input
                            onChange={(e) => handleCheckboxChange(e)}
                            type="checkbox"
                            name="immobilizer_key"
                            id="immobilizer_key"
                            checked={
                              amenities.immobilizer_key === 0 ? false : true
                            }
                          />
                          <label htmlFor="immobilizer_key">
                            Immobilizer Key
                          </label>
                        </div>
                        <div>
                          <input
                            onChange={(e) => handleCheckboxChange(e)}
                            type="checkbox"
                            name="power_locks"
                            id="power_locks"
                            checked={amenities.power_locks === 0 ? false : true}
                          />
                          <label htmlFor="power_locks">Power Locks</label>
                        </div>
                        <div>
                          <input
                            onChange={(e) => handleCheckboxChange(e)}
                            type="checkbox"
                            name="power_windows"
                            id="power_windows"
                            checked={
                              amenities.power_windows === 0 ? false : true
                            }
                          />
                          <label htmlFor="power_windows">Power Windows</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="rear_speakers"
                            id="rear_speakers"
                            checked={
                              amenities.rear_speakers === 0 ? false : true
                            }
                          />
                          <label htmlFor="rear_speakers">Rear Speakers</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            onChange={(e) => handleCheckboxChange(e)}
                            name="steering_switches"
                            id="steering_switches"
                            checked={
                              amenities.steering_switches === 0 ? false : true
                            }
                          />
                          <label htmlFor="steering_switches">
                            Steering Switches
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="btn-section stick-bottom">
                    <button onClick={(e) => handleBackClick(e)}>BACK</button>
                    <button onClick={(e) => handleFinishClick(e)}>
                      FINISH
                    </button>
                  </div>
                </>
              )}
              {isLoading && (
                <div className="center1">
                  <div className="car-loader"></div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IndividualUserSignup;
