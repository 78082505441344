import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import "./MerchantSearchMainArea.scss";
import NoData from "../../assets/images/blue color.png";
import MerchantCard from "../../components/MerchantCard/MerchantCard";
import axios from "axios";
import {
  getVendorMaintenance,
  getVendorParts,
  vendorFilterRoute,
} from "../../utils/ApiRoutes";
import { useNavigate, useParams } from "react-router-dom";
import { merchantDetailsPage } from "../../utils/Routes";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { Pagination } from "@mui/material";
import Map from "../map/Map";
import FilterWithCheckBox from "../FilterWithCheckBox/FilterWithCheckBox";
import MenuIcon from "@mui/icons-material/Menu";
import { dataContext } from "../../App";

const INITIAL_FILTER = {
  badge_id: 0,
  country_id: 0,
  search: "",
  city_id: 0,
  maintenance_id: 0,
  part_types_id: 0,
  service_id: 0,
};

const MerchantAreaMainArea = () => {

  const params = useParams();
  const { countries,maintenance ,parts} = useContext(dataContext);
  const navigate = useNavigate();

  // State variables
  const [vendors, setVendors] = useState([]);
  const [badges, setBadges] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageVendors, setCurrentPageVendors] = useState([]);
  const [vendorFilter, setVendorFilter] = useState(INITIAL_FILTER);
  const [bounds, setBounds] = useState(null);
  const [name, setname] = useState("")
  // Memoized initial state
  const initialStata = useMemo(() => {
    const temp = { ...INITIAL_FILTER };

    if (params.type === "workshop") {
      temp.maintenance_id = params.id;
      delete temp.part_types_id;
    } else {
      temp.part_types_id = params.id;
      delete temp.maintenance_id;
    }
    temp.service_id = params.type === "workshop" ? 2 : 3;

    const uae = countries.find(
      (data) => data.country_code.toLowerCase() === "ae"
    );
    temp.country_id = uae.country_id
    return temp;
  }, [params.type, params.id,countries]);

  // Fetch data
  const getData = useCallback(async () => {
    setIsLoading(true);
    const itemsPerPage = 6;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    try {
      let response;
      if (params.type === "workshop") {
        response = await axios.post(getVendorMaintenance, {
          maintenance_id: params.id,
        });
      } else if (params.type === "parts") {
        response = await axios.post(getVendorParts, {
          part_types_id: params.id,
        });
      }

      if (response) {
        setVendors(response.data.vendor || []);
        setBadges(response.data.Badge || []);
        const currentPageItems =
          response.data.vendor?.slice(startIndex, endIndex) || [];
        setCurrentPageVendors(currentPageItems);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, params.type, params.id]);
  useEffect(() => {
    // Fetch data when route parameters change
    getData();
  }, [params.type, params.id,getData]);
  // Handle filter change
  const handleFilterChange = useCallback(async () => {
    
      try {
        setIsLoading(true);
        const { data } = await axios.post(vendorFilterRoute, vendorFilter);

        if (!data.error) {
          setVendors(data.vendor || []);
          const itemsPerPage = 6;
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          const currentPageItems =
            data.vendor?.slice(startIndex, endIndex) || [];
          setCurrentPageVendors(currentPageItems);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    
  }, [currentPage, vendorFilter]);

  // Effects
  useEffect(() => {
    if (vendorFilter?.city_id  && vendorFilter.city_id !== 0 && vendorFilter.country_id !== 0 && (vendorFilter.maintenance_id !== 0 || vendorFilter.part_types_id !==0) && vendorFilter.service_id !== 0) {
      handleFilterChange();
    }
  }, [currentPage, handleFilterChange, vendorFilter.city_id ,vendorFilter.country_id , vendorFilter.service_id , vendorFilter.maintenance_id, vendorFilter.part_types_id]);

  useEffect(() => {
    const itemsPerPage = 6;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = vendors?.slice(startIndex, endIndex) || [];
    setCurrentPageVendors(currentPageItems);
  }, [currentPage, vendors]);

  // useEffect(() => {
  //   getData();
    
  // }, [countries, getData]);

  useEffect(() => {
    setVendorFilter(initialStata);
  }, [initialStata]);

  // Event Handlers
  const handleCardClick = (url) => {
    navigate(`${merchantDetailsPage}/${url}`);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleMenuClick = () => {
    document.getElementById("filters_menu").style.display = "block";
  };
  useEffect(() => {
    if (params.type === "workshop") {
      const name = maintenance?.filter(item => item?.maintenance_id?.toString() === vendorFilter?.maintenance_id?.toString() )
      setname(`${name[0]?.name} of Maintenance`)
    } else {
      const name = parts?.filter(item => item?.parts_type_id?.toString() === vendorFilter?.part_types_id?.toString() )
      setname(`${name[0]?.name} of Parts`)
    }    
  }, [vendorFilter.maintenance_id, maintenance,params.type,vendorFilter.part_types_id ,parts])
  return (
    <div>
      <div className="blue-back"></div>
      <div className="merchant-search-main">
        <div>
          <FilterWithCheckBox
            vendorFilter={vendorFilter}
            setVendorFilter={setVendorFilter}
            type={params.type}
            id={params.id}
          />
        </div>
        {!isLoading && (
          <>
            <div className="menu-bar">
              <MenuIcon onClick={handleMenuClick} />
            </div>
            <div className="vendors-listing">
              {currentPageVendors?.map((vendor) =>
                vendor?.map((data, i) => (
                  <div style={{ cursor: "pointer" }} key={i}>
                    <MerchantCard
                      vendor={data}
                      badges={badges}
                      badgeId={JSON.parse(data.badge)}
                      handleCardClick={handleCardClick}
                      serviceName={name}
                    />
                  </div>
                ))
              )}
              {vendors.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={NoData}
                    alt="No-data"
                    style={{
                      paddingTop: "2rem",
                      height: "400px",
                      width: "500px",
                    }}
                  />
                </div>
              )}
              <div className="pagination-center">
                {Math.ceil(vendors?.length / 6) > 1 && (
                  <Pagination
                    variant="outlined"
                    page={currentPage}
                    onChange={handlePageChange}
                    count={
                      vendors?.length !== 0 ? Math.ceil(vendors?.length / 6) : 1
                    }
                  />
                )}
              </div>
            </div>
          </>
        )}
        {isLoading && <ProgressCircle height={"60vh"} />}
        <div className="map" style={{ margin: "2rem 0" }}>
          <Map vendors={vendors} bounds={bounds} setBounds={setBounds} />
        </div>
      </div>
    </div>
  );
};

export default MerchantAreaMainArea;
