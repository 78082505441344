import React, { useContext, useEffect, useMemo, useState } from "react";
import { MaintenanceImagesRoute } from "../../utils/ApiRoutes";
import { dataContext } from "../../App";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import axios from "axios";
import { useAuth } from "../../context/auth";
import {UpdateMaintenance as Maintenance} from "../../utils/ApiRoutes"
import {toast} from "react-toastify"
import { toastOptions } from "../../utils/ToastOptions";
const UpdateMaintenance = ({ currentData, setActive }) => {
  const controller = useMemo(() => new AbortController(), []);
  const [auth] = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { maintenance } = useContext(dataContext);
  const [maintenanceIds, setMaintenanceIds] = useState("");
  const [isChecked, setIsChecked] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        let initial = {};

        maintenance.forEach((maintain) => {
          initial[maintain.maintenance_id] = false;
        });

        let ids = currentData.flat(1).map((maintain) => {
          const temp = maintenance.filter(
            (data) => data.name.toLowerCase() === maintain.name.toLowerCase()
          );
          initial[temp[0].maintenance_id] = true;
          return temp[0].maintenance_id;
        });
        setMaintenanceIds(`,${ids.join(",")}`);
        setIsChecked(initial);
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
    return () => controller.abort();
  }, [controller, currentData, maintenance]);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setIsChecked({ ...isChecked, [id]: checked });
    checked
      ? setMaintenanceIds(maintenanceIds.concat(`,${id}`))
      : setMaintenanceIds(maintenanceIds.replace(`,${id}`, ""));
  };

  const handleNextClick = async (e) => {
    e.preventDefault();
    e.target.setAttribute("disabled", true);
    if (maintenanceIds === ""){
      toast.error("Atleast Select One Service" , toastOptions)
      e.target.removeAttribute("disabled");
      return
    }
    setIsLoading(true);
    try {
      if (maintenanceIds !== "") {
        const response = await axios.post(Maintenance, {
          code: auth.user?.code,
          maintenance_ids: maintenanceIds.replace(",", ""),
        });
        if (response.data.error === false) {
          setIsLoading(false);
          setActive("main");
        }
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
    e.target.removeAttribute("disabled");
    setIsLoading(false);
  };

  return (
    <div className="add-service-main-aera">
      {!isLoading && (
        <div className="maintenance-wrapper">
          <div className="maintenance-card-section">
            {maintenance.map((maintain) => (
              <div key={maintain.maintenance_id} className="maintenances">
                <div className="maintenance-image">
                  <img
                    src={`${MaintenanceImagesRoute}/${maintain.image}`}
                    alt=""
                  />
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      id={maintain.maintenance_id}
                      onChange={(e) => handleCheckboxChange(e)}
                      checked={isChecked[maintain.maintenance_id]}
                    />
                  </div>
                </div>
                <h4>
                  {maintain.name.length > 30
                    ? `${maintain.name.slice(0, 30)}...`
                    : maintain.name}
                </h4>
              </div>
            ))}
          </div>
          <button className="edit-btn" onClick={handleNextClick}>
            Update Services
          </button>
        </div>
      )}
      {isLoading && <ProgressCircle height={"calc(100vh - 150px)"} />}
    </div>
  );
};

export default UpdateMaintenance;
