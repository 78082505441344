import "./MerchantNavbar.scss";

import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Person2Icon from "@mui/icons-material/Person2";
import { cities } from "../../assets/data";

import { Link, useNavigate } from "react-router-dom";

import { vendorProfileRoute } from "../../utils/Routes";
import { useAuth } from "../../context/auth";

import CarMenu from "../CarMenu/CarMenu";
import WorkshopMenu from "../WorkshopMenu/WorkshopMenu";
import PartsMenu from "../PartsMenu/PartsMenu";
import { handleLogout } from "../../utils/ReuseFunction";
// import BlinkerMenu from "../BlinkerMenu/BlinkerMenu";
import ResponsiveMenu from "../ResponsiveMenu/ResponsiveMenu";
import { GiHamburgerMenu } from "react-icons/gi";
import NotificationMenu from "../NotificationMenu/NotificationMenu";

const MerchantNavbar = ({ color, textColor }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [profileMenu, setProfileMenu] = useState(false);
  const [active, setActive] = useState("");
  const [currentCity, setCurrentCity] = useState("Dubai");

  return (
    <>
      <div className="distributer" style={{ color: textColor }}>
        <nav className="navbar5">
          <div className="brand">
            <Link to="/">
              <h1 style={{ color: textColor, borderColor: textColor }}>
                CARS'R'US{" "}
              </h1>
            </Link>
            <div
              style={{ position: "relative" }}
              onClick={() =>
                setActive((prev) =>
                  prev === "countryMenu" ? "" : "countryMenu"
                )
              }
            >
              {!(active === "countryMenu") && <span>{currentCity}</span>}
              {active === "countryMenu" ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )}
              {active === "countryMenu" && (
                <ul className="country-menu">
                  {cities
                    .filter((city) => city !== currentCity)
                    .map((filtercity, i) => (
                      <li key={i} onClick={() => setCurrentCity(filtercity)}>
                        {filtercity}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
          <div className="nav-links">
            <div
              className="navlink"
              onClick={() =>
                setActive((prev) => (prev === "carMenu" ? "" : "carMenu"))
              }
            >
              <Link to="/cars">
                <span style={{ color: textColor }}> Cars</span>
              </Link>
              {active === "carMenu" ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              {active === "carMenu" && (
                <CarMenu setActive={setActive} top={"100px"} />
              )}
            </div>
            <div
              className="navlink"
              onClick={() =>
                setActive((prev) =>
                  prev === "workshopMenu" ? "" : "workshopMenu"
                )
              }
            >
              <Link to={"/workshop"}>
                <span style={{ color: textColor }}>Workshops</span>
              </Link>
              {active === "workshopMenu" ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              {active === "workshopMenu" && (
                <WorkshopMenu setActive={setActive} top={"100px"} />
              )}
            </div>
            <div
              className="navlink"
              onClick={() =>
                setActive((prev) => (prev === "partsMenu" ? "" : "partsMenu"))
              }
            >
              <Link to={"/parts"}>
                <span style={{ color: textColor }}>Parts</span>
              </Link>
              {active === "partsMenu" ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
              {active === "partsMenu" && (
                <PartsMenu top={"100px"} setActive={setActive} />
              )}
            </div>
          </div>
          {auth.userType !== 1 && (<div className="live-search-link">
            <div className="white-circle" style={{ background: "white" }} />
            <Link to="/search">Live Search</Link>
            {/* <BlinkerMenu /> */}
          </div>)}
          <div className="right-navigation">
            {!auth.user && (
              <>
                <Link to={"/login"} className="btn btn-primary">
                  <span style={{ color: textColor }}>Log in</span>
                </Link>
                {/* <Link to={"/usersignup"}>
                  <span style={{ color: textColor }}>Sign Up</span>
                </Link> */}
                <Link to={"/dashboard"} className="btn-dark btn">
                  Register
                </Link>
                {/* <Link
                  to={"/dashboard/vender-individual-signup"}
                  className="btn-primary btn"
                >
                  Sell My Car
                </Link>{" "} */}
              </>
            )}
            {auth.user && (
              <>
              {auth.userType  === 1 && <NotificationMenu/>}
              <div className="prodile-section">
                <div className="profile-image">
                  <Person2Icon />
                </div>
                <div className="profile-name">Welcome {auth.user.name}</div>
                <div
                  className="down-icon"
                  onClick={() => setProfileMenu((prev) => !prev)}
                >
                  <ArrowDropDownIcon />
                </div>
                {profileMenu && auth.userType === 2 && (
                  <ul className="profile-menu">
                    <li onClick={() => navigate("/profile")}>Profile</li>
                    <li onClick={() => navigate("/my-products")}>
                      My Products
                    </li>
                    <li onClick={() => handleLogout(auth,setAuth,auth.userType)}>Sign Out</li>
                  </ul>
                )}
                {profileMenu && auth.userType === 1 && (
                  <ul className="profile-menu">
                    <li
                      onClick={() =>
                        navigate(`${vendorProfileRoute}/${auth.user?.url}`)
                      }
                    >
                      Profile
                    </li>
                    <li onClick={() => handleLogout(auth,setAuth,auth.userType)}>Sign Out</li>
                  </ul>
                )}
              </div>
              </>
            )}
          </div>
        </nav>
      </div>
      <div
        className="home-menu-icon"
        onClick={() =>
          setActive((prev) => (prev === "sidemenu" ? "" : "sidemenu"))
        }
        style={{background:color}}
      >
        <div className="brand" >
          <Link to="/">
            <h1 style={{color:textColor ,borderColor:textColor }}>CARS'R'US </h1>
          </Link>
        </div>
        <GiHamburgerMenu color={textColor} />
      </div>
      <ResponsiveMenu setActive={setActive} active={active} />
    </>
  );
};

export default MerchantNavbar;
