import React, { useState, useEffect } from "react";

import "../AdSection/AdSection.scss";
import HorizontalProductCardSmall from "../HorizontalProductCardSmall/HorizontalProductCardSmall";

import axios from "axios";
import {  userProductListing } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";
import { userAdPaymentRoute } from "../../utils/Routes";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import { useAuth } from "../../context/auth";
import moment from 'moment';
import UserProductEditDropdown from "../UserProductEditDropdown/UserProductEditDropdown";
import GrayNoData from "../../assets/images/gray-nodata.svg"

const ProductSection = () => {
  const navigate = useNavigate()
  const [ads, setAds] = useState([]);
  const [advalidater, setAdvalidater] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [auth ] =useAuth()

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    setIsLoading(true)
    try {
      const [response] =  await Promise.all([
        axios.get(`${userProductListing}/${auth.user.code}`),
      ]) 
      setAdvalidater(response?.data?.Ads);
      setAds(response.data.product);
    } catch (error) {
      console.error(error.message);
    }
    setIsLoading(false)
  };
  const handlePromoteClick = (url,code)=>{
    navigate(`${userAdPaymentRoute}/${url}?code=${code}`)
  }
  return (
    <>
    {!isLoading && (<div className="ad-section">
      <h2>Your Products</h2>
      <div className="ads-area">
        <table>
          <tr>
            <th>
              <input type="hidden" name="" id="" />
            </th>
            <th> Product Detail</th>
            <th> Date Posted</th>
            <th> Views</th>
            <th> Messages</th>
            <th > Promotions</th>
            <th > Options</th>
          </tr>
          {ads.map((ad) => (
            <>
              <tr>
                <td>{/* <input type="checkbox" name="" id="" /> */}</td>
                <td>
                  <HorizontalProductCardSmall ad={ad}/>
                </td>
                <td>{moment(ad?.date).format("DD/MM/YYYY")}</td>
                <td>{ad.views}</td>
                <td>{ad.message}</td>
                <td className="btn-section">
                  {advalidater.findIndex(item => item.code ===ad.code) !== -1 ? <button className="red-button" style={{cursor:"no-drop"}} disabled={true}> Promoted</button> : <button className="red-button" onClick={() => handlePromoteClick(ad?.url, ad?.code)}> Promote</button>}
                </td>
                <td className="btn-section">
                <UserProductEditDropdown id={ad?.code}/> 
                </td>
              </tr>
              <div className="btn-section-responsive">
              {advalidater.findIndex(item => item.code ===ad.code) !== -1 ? <button className="red-button" style={{cursor:"no-drop"}} disabled={true}> Promoted</button> : <button className="red-button" onClick={() => handlePromoteClick(ad?.url, ad?.code)}> Promote</button>}
              </div>
            </>
          ))}
          
        </table>
        
      </div>
      {ads.length === 0  && (
            <div style={{display:"flex" , justifyContent:"center" }}>
                <img src={GrayNoData} alt="No-Data" width={500} height={500}/>
            </div>
          )}
      <div className="add-ad" style={{cursor:"pointer"}} onClick={()=>navigate("/dashboard/vender-individual-signup")}>+ Add Another Car for as low as 5000 AED </div>
    </div>)}
    {isLoading && (
      <ProgressCircle height={"calc(100vh - 125px)"}/>
    )}
    </>
  );
};

export default ProductSection;
