import "./HorizontalProductCard.scss";

import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";

import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { Doors, Colors } from "../../assets/data";
import { Door } from "../../assets/icons/svg";
import CallIcon from "@mui/icons-material/Call";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SpeedIcon from "@mui/icons-material/Speed";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { cardRoute, insertChatMessage, productRoute } from "../../utils/ApiRoutes";
import { useAuth } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import { productDetail } from "../../utils/Routes";
import moment from "moment";
import { toastOptions } from "../../utils/ToastOptions";
import { toast } from "react-toastify";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
const HorizontalProductCard = ({
  product,
  ad,
  handleCompareProductClick,
  bookmarkedProduct,
  handleBookmarkClick,
}) => {
  const [auth] = useAuth();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${productDetail}/${product?.url}`);
  };
  const handleCallClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (auth.user) {
      navigator.clipboard.writeText(product.phone);
      toast.success("Phone Number is copied in clipboard", toastOptions);
    } else {
      toast.error("Please Login to get Phone Number", toastOptions);
    }
  };

  const handleChatClick = async (e, id, receiver, type, error) => {
    e.stopPropagation();
    e.preventDefault();
    if (!error) {
      if (auth.user) {
        await axios.post(insertChatMessage, {
          sender_id: auth.user.user_id,
          receiver_id: id,
          message: `Customer ${auth.user.name} have query for this product http://${window.location.host}/product/${product?.url}`,
          sender: auth.userType === 2 ? "users" : "vendor",
          receiver,
          type,
        });
        await axios.get(`${productRoute}/${product.url}/Chat/Enter`);
        auth.userType === 2
          ? navigate(`/my-chat?id=${id}&type=${type}`)
          : navigate(`/dashboard/business-chats?id=${id}&type=${type}`);
      } else {
        toast.error("Please login to chat", toastOptions);
      }
    } else {
      toast.error("You Cannot Chat with yourself", toastOptions);
    }
  };


  return (
    <div style={{ position: "relative" }}>
      <div
        className="gallery"
        onClick={(e) =>
          handleCompareProductClick(
            e,
            product?.card,
            product?.url,
            product?.product_id
          )
        }
      >
        <CompareArrowsIcon />
      </div>
      <div
        className={
          auth.user &&
          bookmarkedProduct.find(
            (data) => data.product_id === product.product_id
          )
            ? "favourite fav-active"
            : "favourite"
        }
        onClick={() => handleBookmarkClick(product.url, product.product_id)}
      >
        <FavoriteBorderOutlinedIcon />
      </div>

      <div className="horizontal-product-card" onClick={handleClick}>
        <div className="product-image">
          <img src={`${cardRoute}/${product.card}`} alt="card" />
          {ad && (
            <div className="ad-pill">
              <span>Ad</span>
            </div>
          )}
          {product.plan_type === 2 && (
            <div className="featured-badge-product">Featured</div>
          )}
          {product.plan_type === 3 && (
            <div className="featured-badge-product">Premium</div>
          )}
          {product.product_type === "Ads" && (
            <div className="ad-pill">
              <span>Ad</span>
            </div>
          )}
        </div>
        <div className="product-desc">
          <div className="left-side-of-card">
            <div className="product-type">
              <h6>{product.brand_name}</h6>
              <span />
              <p>{product.model_name}</p>
            </div>
            <div className="product-price">
              AED: <strong>{product.price}</strong>
            </div>
            <div className="product-specification">
              <span>
                <CalendarTodayIcon /> {product.model_year}
              </span>
              <span>
                <SpeedIcon /> {product.milage} km
              </span>
              <span>
                  <ColorLensIcon />{" "}
                  {product?.color_id === 1
                    ? product.unlisted_color
                    : Colors[Number(product?.color_id)]}
                </span>
                <span>
                  <Door /> {Doors[Number(product?.door_id)]}
                </span>
            </div>
            <div className="product-location">
              <span>
                <LocationOnOutlinedIcon />
                {product.location_name}, {product.city_name}
              </span>
            </div>
            uploaded: {moment(`${product.date} ${product.time}`, 'YYYY-MM-DD HH:mm:ss').fromNow()}

            <div className="Uplaoded-date">
            </div>
            {/* <div className="services">
              <span>Test Drive</span>
              <span className="black-disc" />
              <span>Home Delivery</span>
              <span className="black-disc" />
              <span>Online Documents</span>
            </div> */}
            <div className="btn-section">
            <button onClick={(e) => handleCallClick(e)}>
                  <CallIcon /> Call
                </button>
                {(product.uploaded_by !== 2 && auth.userType !== 1) && (<button
                  onClick={(e) =>
                    handleChatClick(
                      e,
                      product?.uploader_id,
                      product.uploaded_by === 2 ? "users" : "vendor",
                      auth.userType === 2 && product.uploaded_by === 2
                        ? 0
                        : auth.userType === 1 && product.uploaded_by === 1
                        ? 2
                        : 1,
                      auth.userType === 2 &&
                        product.uploaded_by === 2 &&
                        auth.user.user_id === product?.uploader_id
                        ? true
                        : auth.userType === 1 &&
                          product.uploaded_by === 1 &&
                          auth.user.user_id === product?.uploader_id
                        ? true
                        : false
                    )
                  }
                  style={{cursor:auth.user ? auth.userType === 2 ? product.uploaded_by === 2 ?   "not-allowed" : "pointer" : "not-allowed" : "pointer"}}
                >
                  <ChatBubbleOutlineRoundedIcon /> Chat
                </button>)}
              </div>
          </div>
          <div className="right-side-of-card">
            {/* <div className="listing-tag">New Listing</div> */}
            {/* <div className="installment"><strong>2500</strong> /Month</div> */}
            {/* <div className="certified-by">
                Certified by whoever
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalProductCard;
