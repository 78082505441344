import React, { useState, useEffect, useContext } from "react";

import "./ImportAndExportMainArea.scss";
import FilterWithCheckBox from "../FilterWithCheckBox/FilterWithCheckBox";
import MerchantCard from "../MerchantCard/MerchantCard";
import axios from "axios";
import { getVendors, vendorFilterRoute } from "../../utils/ApiRoutes";
import { useNavigate } from "react-router-dom";
import { merchantDetailsPage } from "../../utils/Routes";
import { Pagination } from "@mui/material";
import { toastOptions } from "../../utils/ToastOptions";
import { toast } from "react-toastify";
import MenuIcon from "@mui/icons-material/Menu";
import { dataContext } from "../../App";
import moment from "moment";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import GrayNoData from "../../assets/images/gray-nodata.svg";

const ImportAndExportMainArea = () => {
  const navigate = useNavigate();
  const { countries } = useContext(dataContext);
  const [vendors, setVendors] = useState([]);
  const [badges, setbadges] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageVendors, setCurrentPageVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // filter State
  const [vendorFilter, setVendorFilter] = useState({
    badge_id: 0,
    country_id: 0,
    search: "",
    city_id: 0,
    service_id: 5,
  });

  useEffect(() => {
    if (vendorFilter?.city_id  && vendorFilter.city_id !== 0 && vendorFilter.country_id !== 0 ) {
      FilterChange();
    }
    // eslint-disable-next-line
  }, [
    vendorFilter.badge_id,
    vendorFilter.search,
    vendorFilter.city_id,
    vendorFilter.country_id,
  ]);

  const FilterChange = async () => {
    if (
      vendorFilter.badge_id !== 0 ||
      vendorFilter.search !== "" ||
      vendorFilter.city_id !== 0 ||
      vendorFilter.country_id !== 0
    ) {
      try {
        setIsLoading(true);
        const { data } = await axios.post(vendorFilterRoute, vendorFilter);
        if (data.error === false) {
          setVendors(data.vendor);
          const itemsPerPage = 9; // Number of items to display per page
          const startIndex = (currentPage - 1) * itemsPerPage;
          const endIndex = startIndex + itemsPerPage;
          const currentPageItems = [
            ...data.vendor?.slice(startIndex, endIndex),
          ];
          setCurrentPageVendors(currentPageItems);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      getData();
    }
  };

  useEffect(() => {
    const uae = countries.find(
      (data) => data.country_code.toLowerCase() === "AE".toLowerCase()
    );
    setVendorFilter((prev) => ({ ...prev, country_id: uae.country_id }));
    if (vendors.length === 0) {
      getData();
    }
    const geolocationAPI = navigator.geolocation;
    if (!geolocationAPI) {
      toast.error(
        "Geolocation API is not available in your browser!",
        toastOptions
      );
    } else {
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;
          setVendorFilter((prev) => ({
            ...prev,
            latitude: coords.latitude,
            longitude: coords.longitude,
          }));
        },
        (error) => {
          toast.error(
            "Something went wrong getting your position!",
            toastOptions
          );
        }
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const itemsPerPage = 5; // Number of items to display per page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageItems = [...vendors?.slice(startIndex, endIndex)];
    setCurrentPageVendors(currentPageItems);
    // eslint-disable-next-line
  }, [currentPage]);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${getVendors}/5`);
      setVendors(response.data.vendor);
      setbadges(response.data.Badge);
      const itemsPerPage = 5; // Number of items to display per page
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const currentPageItems = [
        ...response.data.vendor?.slice(startIndex, endIndex),
      ];
      setCurrentPageVendors(currentPageItems);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = (url) => {
    navigate(`${merchantDetailsPage}/${url}`);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleMenuClick = () => {
    document.getElementById("filters_menu").style.display = "block";
  };
 
  return (
    <div className="import-and-export-main">
      <div className="left-side">
        <FilterWithCheckBox
          vendorFilter={vendorFilter}
          setVendorFilter={setVendorFilter}
        />
      </div>
      <div className="right-side10">
        {!isLoading && (
          <>
            <div className="menu-bar">
              <MenuIcon onClick={handleMenuClick} />
            </div>
            <div className="section-header">
              <h1>Importing / Exporting</h1>
              <h2>Merchant that offer car importing and exporting</h2>
            </div>
            <div className="results">
              <p>
                {vendors?.length} results • {moment().format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="merchants">
              {currentPageVendors.flat(1)?.map((vendor, i) => (
                <div
                  style={{ cursor: "pointer" }}
                  // onClick={() => handleCardClick(vendor.url)}
                  key={i}
                >
                  <MerchantCard
                    key={vendor.vendor_id}
                    badges={badges}
                    badgeId={JSON.parse(vendor.badge)}
                    vendor={vendor}
                    handleCardClick={handleCardClick}
                  />
                </div>
              ))}

              {vendors.length === 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src={GrayNoData}
                    alt="No-Data"
                    width={500}
                    height={500}
                  />
                </div>
              )}
            </div>
            <div className="pagination-center">
              {Math.ceil(vendors?.length / 5) > 1 && (
                <Pagination
                  variant="outlined"
                  page={currentPage}
                  onChange={handlePageChange}
                  count={
                    vendors?.length !== 0 ? Math.ceil(vendors?.length / 5) : 1
                  }
                />
              )}
            </div>
          </>
        )}
        {isLoading && <ProgressCircle height={"80vh"} />}
      </div>
    </div>
  );
};

export default ImportAndExportMainArea;
