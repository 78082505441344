import "./AddServiceMainArea.scss";
import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "../../context/auth";
import axios from "axios";
import {
  MaintenanceImagesRoute,
  brandLogoRoute,
  getVendorServices,
} from "../../utils/ApiRoutes";
import ProgressCircle from "../ProgressCIrcle/ProgressCircle";
import UpdateBrand from "./UpdateBrand";
import UpdateMaintenance from "./UpdateMaintenance";

const AddServiceMainArea = () => {
  const controller = useMemo(() => new AbortController(), []);
  const [auth] = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState({});

  const [active, setActive] = useState("main")

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `${getVendorServices}/${auth.user?.url}`
        );

        setServices(data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (active==="main") {
      getData();
    }
    return () => controller.abort();
  }, [auth.user?.url, controller , active]);





  return (
    <>
      <div className="add-service-main-aera">
        {active === "main" && (<div className="services-section">
          {services?.BRAND?.length > 0 && <h3>Brands</h3>}
          <div className="brands-section">
            {services?.BRAND?.map((brands) =>
              brands?.map((brand, i) => (
                <div key={i} className="brand-card">
                  <img
                    src={`${brandLogoRoute}/${brand?.logo}`}
                    alt={brand?.name}
                  />
                  <p>{brand?.name}</p>
                </div>
              ))
            )}
          </div>
          {services?.BRAND?.length > 0 &&(<button className="edit-btn" onClick={() =>setActive("brands")}>Edit Brands</button>)}
          {services?.IMPORT?.length > 0 && services?.EXPORT?.length > 0 && (
            <div className="import-and-export-section">
              <div className="imports">
                <h4>Importing From</h4>
                <div className="import-country">
                  {services?.IMPORT?.map((imports) =>
                    imports?.map((data, i) => (
                      <div key={i} className="brand-card">
                        <div
                          className={`flag flag-${data.country_code.toLowerCase()}`}
                        ></div>
                        <p>{data?.name}</p>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="exports">
                <h4>Exporting To</h4>
                <div className="export-country">
                  {services?.EXPORT?.map((exports) =>
                    exports?.map((data, i) => (
                      <div key={i} className="brand-card">
                        <div
                          className={`flag flag-${data.country_code.toLowerCase()}`}
                        ></div>
                        <p>{data?.name}</p>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
          {services?.MAINTENANCE?.length > 0 && <h3>Services</h3>}
          <div className="brands-section">
            {services?.MAINTENANCE?.map((maintenance) =>
              maintenance?.map((maintain, i) => (
                <div key={i} className="brand-card">
                  <img
                    src={`${MaintenanceImagesRoute}/${maintain?.image}`}
                    alt={maintain?.name}
                  />
                  <p>{maintain?.name}</p>
                </div>
              ))
            )}
          </div>
          {services?.MAINTENANCE?.length > 0 &&(<button className="edit-btn" onClick={() =>setActive("maintain")}>Edit Service</button>)}
        </div>)}

        {active === "brands" &&(<UpdateBrand currentData={services?.BRAND} setActive={setActive}/>)}
        {active === "maintain" &&(<UpdateMaintenance currentData={services?.MAINTENANCE} setActive={setActive}/>)}
        {isLoading && <ProgressCircle height={"calc(100vh - 150px)"} />}
      </div>
    </>
  );
};

export default AddServiceMainArea;
