import React from "react";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotOTPRoute, resendRoute } from "../../utils/ApiRoutes";
import axios from "axios";

import OtpInput from "react-otp-input";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { toastOptions } from "../../utils/ToastOptions";
import { useAuth } from "../../context/auth";
import { Helmet } from "react-helmet";
import { CircularProgress } from "@mui/material";

const ForgotOTP = () => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  
  const controller = new AbortController();

  const handleOTPSubmit = async (e) => {
    setIsLoading(true)
    e.preventDefault();
    const response = await axios.post(forgotOTPRoute, {
      code: localStorage.getItem("code"),
      otp,
    },{signal :controller.signal});
    if (response.data.error === false) {
      navigate("/newpassword");
    }
    if (response.data.error === true) {
      toast.error("invalid OTP!", toastOptions);
      setOtp("");
    }
    setIsLoading(false)
  };

  const handleResend = async (e) => {
    e.preventDefault();
    const response = await axios.get(`${resendRoute}/${localStorage.getItem("code")}`);
  };

  return (
    <>
      <Helmet>
        <title>OTP | CARS'R'US</title>
      </Helmet>
      <form className="otp-verify" style={{ backgroundColor: "#eeeee4" }}>
        <div className="form">
          <h1>OTP</h1>
          <h4>Enter Otp sent to your Email to verify its your email.</h4>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              fontSize: "18px",
              border: "1px solid #d1d1d1",
              height: "px",
              padding: "1rem",
              width: "2.5em",
              borderRadius: "4.49871px",
            }}
            inputType="tel"
          />
          <div className="btn-section">
            <button className="btn-blue" onClick={(e) => handleOTPSubmit(e)}>{isLoading ?  <CircularProgress size={22} color="inherit" /> : "Submit"}</button>
            <button className="btn-darkgray" onClick={(e) => handleResend(e)}>Resend</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ForgotOTP;
