import React, { useContext, useState, useEffect, useCallback } from "react";

import "./FilterWithCheckBox.scss";

import SearchIcon from "@mui/icons-material/Search";

import IconButton from "@mui/material/IconButton";
import { dataContext } from "../../App";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { getCitiesRoute, vendorBadgesGetRoute } from "../../utils/ApiRoutes";
import { Dropdown } from "primereact/dropdown";
const FilterWithCheckBox = ({ vendorFilter, setVendorFilter, type, id }) => {
  const { countries, maintenance, parts } = useContext(dataContext);
  const [search, setSearch] = useState("");
  const [cities, setCities] = useState([]);
  const [badges, setBadges] = useState([]);
  const [maintenanceObj, setMaintenanceObj] = useState({})
  const [partsObj, setPartsObj] = useState({})
  useEffect(() => {
    getBadges();
  }, []);
  const getCities = useCallback(
    async () => {
      const { data } = await axios.get(
        `${getCitiesRoute}/${vendorFilter.country_id}`
      );
      setCities(data.city);
      setVendorFilter((prev) => ({ ...prev, city_id: data.city[0]?.city_id }));
    },
    //eslint-disable-next-line
    [vendorFilter.country_id , type ,id]
  );

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    if (type && type === "workshop") {
      const selected = maintenance.filter((data)=> Number(data.maintenance_id) === Number(id) )
      setMaintenanceObj(selected[0])
    }
    if (type && type === "parts") {
      const selected = parts.filter((data)=> Number(data.parts_type_id) === Number(id) )
      console.log(selected);
      setPartsObj(selected[0])
    }
  }, [type,id,maintenance,parts])
  

  const getBadges = async () => {
    const { data } = await axios.get(vendorBadgesGetRoute);
    setBadges(data.badge);
  };

  const handleCountryChange = async (e) => {
    const { value, name } = e.target;
    setVendorFilter((prev) => ({ ...prev, [name]: value }));
  };
  const handlePillChange = (value, name) => {
    if (value !== vendorFilter[name]) {
      setVendorFilter((prev) => ({ ...prev, [name]: value }));
    } else {
      setVendorFilter((prev) => ({ ...prev, [name]: 0 }));
    }
  };
  useEffect(() => {
    if (search === "") setVendorFilter((prev) => ({ ...prev, search }));
    // eslint-disable-next-line
  }, [search]);

  const handleCloseClick = () => {
    document.getElementById("filters_menu").style.display = "none";
  };

  const handleRadioChange = (e) => {
    // console.log(e.target.id);
    setVendorFilter((prev) => ({ ...prev, city_id: Number(e.target.id) }));
  };

  const handleChange = (e) => {
    if (e.target.id === "maintenance_select") {
      setVendorFilter((prev) => ({ ...prev, maintenance_id: e.value.maintenance_id }))
      setMaintenanceObj(e.value)
    }
    if (e.target.id === "parts_select") {
      setVendorFilter((prev) => ({ ...prev, part_types_id: e.value.maintenance_id }))
      setPartsObj(e.value)
    }
  }
  return (
    <div className="filters" id="filters_menu">
      <div className="filter-header">
        <div>
          <h4>Filter</h4>
          <h5>No Filter applied</h5>
        </div>
        <div className="close-icon" onClick={handleCloseClick}>
          <CloseIcon />
        </div>
      </div>
      <div className="search-bar">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setVendorFilter((prev) => ({ ...prev, search }));
          }}
        >
          <IconButton
            sx={{ position: "absolute" }}
            onClick={() => setVendorFilter((prev) => ({ ...prev, search }))}
          >
            <SearchIcon />
          </IconButton>
          <input
            type="text"
            name="search"
            id="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Business"
          />
        </form>
      </div>
      <div className="country">
        <select
          name="country_id"
          id="country_id"
          onChange={handleCountryChange}
          value={vendorFilter.country_id}
        >
          <option value="0">Select Country</option>
          {countries.map((country) => (
            <option key={country.country_id} value={country.country_id}>
              {country.name}
            </option>
          ))}
        </select>
      </div>
      <div className="checkbox-section">
        <div className="checkbox-header">
        </div>
        <div className="sub-contouries">
          {cities.map((city, i) => (
            <label htmlFor="usa" key={i}>
              <input
                type="radio"
                checked={vendorFilter.city_id === city.city_id}
                onChange={handleRadioChange}
                name="city_exporting"
                id={city.city_id}
              />{" "}
              {city.name}
            </label>
          ))}
        </div>
      </div>
      <div className="checkbox-section">
        <div className="checkbox-header">Feature</div>
        <div className="cities">
          {badges?.map((badge) => (
            <span
              className={
                Number(vendorFilter?.badge_id) === Number(badge.badge_id)
                  ? "active"
                  : ""
              }
              key={badge.badge_id}
              onClick={() => handlePillChange(badge.badge_id, "badge_id")}
            >
              {badge.name}
            </span>
          ))}
        </div>        
      </div>
      <div className="checkbox-section">
      {type && (<div className="checkbox-header">Service</div>)}
      {type && type === "workshop" && (
          <div>
            <Dropdown
              value={maintenanceObj}
              onChange={(e) => handleChange(e)}
              options={maintenance}
              optionLabel="name"
              placeholder="Select a Maintenance"
              className="w-full md:w-14rem"
              id="maintenance_select"
            />
          </div>
        )}
        {type && type === "parts" && (
          <div>
           <Dropdown
              value={partsObj}
              onChange={(e) => handleChange(e)}
              options={parts}
              optionLabel="name"
              placeholder="Select a Parts"
              className="w-full md:w-14rem"
              id="parts_select"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterWithCheckBox;
