import React, { useEffect, useState, useRef, useMemo } from "react";
import "./VendorDashboardChatMain.scss";
import Avatar from "../../assets/images/avatar.jpg";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import {
  DeleteNotificationStatus,
  getChatList,
  getChatMessage,
  insertChatMessage,
  insertNotificationStatus,
  profileImageRoute,
  userProfilePictureRoute,
  vendorMessageImageRoute,
} from "../../utils/ApiRoutes";
import Welcome from "../../assets/images/welcome.png";
import { useAuth } from "../../context/auth";
import { Rating } from "@mui/material";
import moment from "moment";
import { MdClose } from "react-icons/md";
import { useSearchParams } from "react-router-dom";

const VendorDashboardChatMain = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef(null);
  const bottomRef = useRef();
  const scrollRef = useRef();
  const [auth] = useAuth();
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [active, setActive] = useState(false);
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredChats, setFilteredChats] = useState([]);
  const [key1, setKey1] = useState([]);
  useEffect(() => {
    getData();
    if (searchParams.get("id") && searchParams.get("type")) {
      handleChatClick(searchParams.get("id"), searchParams.get("type"));
    }
    
    // eslint-disable-next-line
  }, []);

  const getData = async () => {
    // setLoading(true);
    const { data } = await axios.post(getChatList, {
      key: auth.user.user_id,
      chat_for: "vendor",
    });
    setChats(data.Chat_List.flat(1));
    setFilteredChats(data.Chat_List.flat(1));
    // setLoading(false);
  };

  const handleChatClick = async (id, type) => {
    const { data } = await axios.post(getChatMessage, {
      key_1: `${auth.user.user_id} vendor`,
      key_2: parseInt(type) === 1 ? `${id} users` : `${id} vendor`,
      type:parseInt(type),
    });

    if (user?.user_id) {
      await axios.post(DeleteNotificationStatus, {
        receiver: "users",
        receiver_id: id,
        sender: "vendor",
        sender_id: auth.user.user_id,
        from: "web",
      });
    }
    await axios.post(insertNotificationStatus, {
      receiver: "users",
      receiver_id: id,
      sender: "vendor",
      sender_id: auth.user.user_id,
      from: "web",
    });


    setMessages(data?.Chat);
    setUser({ ...data?.Key2[0], type:parseInt(type) });
    setKey1({ ...data?.Key1[0] });
    if (searchParams.get("id") && searchParams.get("type")) {
      searchParams.delete("id");
      searchParams.delete("type");
    }
    setSearchParams(searchParams);
    setActive(true);

  };

  useEffect(() => {
    timeoutRef.current = setInterval(function () {
      // console.log(user);
      getMessages(user.type, user.type === 1 ? user.user_id : user.vendor_id);
      getData()
    }, 10000);
    scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
    return async () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (user?.user_id) {
        await axios.post(DeleteNotificationStatus, {
          receiver: "users",
          receiver_id: user.user_id,
          sender: "vendor",
          sender_id: auth.user.user_id,
          from: "web",
        });
      }
    };

    // eslint-disable-next-line
  }, [active, user.type, user.user_id, user.vendor_id]);

  const getMessages = async (type, id) => {
    
    if (active === false) {
      return;
    } else {
      const { data } = await axios.post(getChatMessage, {
        key_1: `${auth.user.user_id} vendor`,
        key_2:  parseInt(type) === 1 ? `${id} users` : `${id} vendor`,
        type:   parseInt(type)
      });

      if (data?.Chat.length !== messages.length) {
        setMessages(data?.Chat);
      }
    }
  };

  const handleSendClick = async (event, id, receiver, type) => {
    if (event.key === "Enter" || event.type === "click") {
      if (message === "") {
        return;
      }
      const temp = message;
      setMessage("");
      const { data } = await axios.post(insertChatMessage, {
        sender_id: auth.user.user_id,
        receiver_id: id,
        message: temp,
        sender: "vendor",
        receiver,
        type,
      });
      if (data.error === false) {
        const { data } = await axios.post(getChatMessage, {
          key_1: `${auth.user.user_id} vendor`,
          key_2: type === 1 ? `${id} users` : `${id} vendor`,
          type:parseInt(type),
        });
        setMessages(data?.Chat);
      } else {
        console.log("something Went wrong");
      }
    }
    scrollToBottom();
  };

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);

    let filteredChat = chats?.filter((item) => {
      const temp = item?.user_name.toLowerCase();
      return temp.includes(value.toLowerCase());
    });
    setFilteredChats(filteredChat);
  };

  const currentUserType = useMemo(
    () => (auth.userType === 2 ? "users" : "vendor"),
    [auth.userType]
  );

  return (
    <div style={{ paddingLeft: "1rem" }}>
      <div className="vendor-chat-area-main">
        {!loading && (
          <div className="chats-lists">
            <div
              className="search-box"
              style={{ paddingBottom: "1rem", paddingTop: ".4rem" }}
            >
              <input
                type="text"
                placeholder="Search Vendor by name..."
                value={search}
                onChange={handleSearchChange}
              />
            </div>
            {filteredChats?.map((chat, i) =>
              Object.keys(chat).length > 3 ? (
                <div
                  className="chat-card"
                  key={i}
                  onClick={() => handleChatClick(chat?.vendor_id, 2)}
                >
                  <div className="profile-image">
                    {chat?.profile ? (
                      <img
                        src={`${profileImageRoute}/${chat?.profile}`}
                        alt="profile"
                      />
                    ) : (
                      <img src={Avatar} alt="profile" />
                    )}
                  </div>
                  <div className="chat-details">
                    <div className="top">
                      <h4>{chat?.shop_name}</h4>
                      <Rating value={Number(chat?.rating)} readOnly />
                    </div>
                    <div className="bottom">{/* <p>{chat?.message}</p> */}</div>
                  </div>
                </div>
              ) : (
                <div
                  className="chat-card"
                  key={i}
                  onClick={() => handleChatClick(chat?.user_id, 1)}
                >
                  <div className="profile-image">
                    {chat?.user_image ? (
                      <img
                        src={`${userProfilePictureRoute}/${chat?.user_image}`}
                        alt="profile"
                      />
                    ) : (
                      <img src={Avatar} alt="profile" />
                    )}
                  </div>
                  <div className="chat-details">
                    <div className="top">
                      <h4>{chat?.user_name}</h4>
                    </div>
                    <div className="bottom">{/* <p>{chat?.message}</p> */}</div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
        {loading && (
          <div
            className="chats-lists"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            <div className="chat-loader"></div>
          </div>
        )}
        {active && (
          <div className="chat-section">
            <div className="top">
              <div className="wrapper">
                <div className="profile">
                  {Object.keys(user).length === 3 ? (
                    user.user_image !== null ? (
                      <img
                        src={`${profileImageRoute}/${user?.profile}`}
                        alt="Profile"
                      />
                    ) : (
                      <img src={Avatar} alt="profile" />
                    )
                  ) : user.profile !== null ? (
                    <img
                      src={`${userProfilePictureRoute }/${user?.user_image}`}
                      alt="profile"
                    />
                  ) : (
                    <img src={Avatar} alt="profile" />
                  )}
                </div>
                <div className="profile-details">
                  <h4>{user?.user_name}</h4>
                </div>
              </div>
              <div onClick={() => setActive(false)}>
                <MdClose />
              </div>
            </div>
            <div className="middle">
              <div className="messages-section">
                {messages?.map((message) => (
                  <>
                    {!(
                      message.sender_id === auth.user.user_id &&
                      message.sender === currentUserType
                    ) ? (
                      <div className="reply" ref={bottomRef}>
                        <div className="profile">
                          {user?.user_image ? (
                            <img
                              src={`${userProfilePictureRoute}/${user?.user_image}`}
                              alt=""
                            />
                          ) : (
                            <img src={Avatar} alt="" />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: ".5rem",
                          }}
                        >
                          <div className="message">
                            {message?.message}
                            <span>
                              {moment(message?.date, "YYYY-MM-DD").format(
                                "YYYY-MM-DD"
                              )}{" "}
                              {moment(message?.time, "hh:mm:ss").format(
                                "h:mm a"
                              )}
                            </span>
                          </div>
                          {message.file !== null && (
                            <div className="message" ref={bottomRef}>
                              <img
                                src={`${vendorMessageImageRoute}/${message?.file}`}
                                alt="Search"
                                style={{ width: "100%" }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="sent" ref={bottomRef}>
                        <div className="profile">
                          {key1?.profile ? (
                            <img
                              src={`${profileImageRoute}/${key1.profile}`}
                              alt="Profile"
                            />
                          ) : (
                            <img src={Avatar} alt="" />
                          )}
                        </div>
                        <div className="message">
                          {message?.message}
                          <span>
                            {moment(message?.date, "YYYY-MM-DD").format(
                              "YYYY-MM-DD"
                            )}{" "}
                            {moment(message?.time, "hh:mm:ss").format("h:mm a")}
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
              <div ref={scrollRef}></div>
            </div>
            <div className="bottom">
              <div className="input">
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={(e) =>
                    handleSendClick(
                      e,
                      user.type === 1 ? user.user_id : user?.vendor_id,
                      user.type === 1 ? "users" : "vendor",
                      user.type
                    )
                  }
                />
                <span
                  onClick={(e) =>
                    handleSendClick(
                      e,
                      user.type === 1 ? user.user_id : user?.vendor_id,
                      user.type === 1 ? "users" : "vendor",
                      user.type
                    )
                  }
                >
                  <SendIcon />
                </span>
              </div>
            </div>
          </div>
        )}
        {!active && (
          <div className="welcome-screen">
            <img src={Welcome} alt="welcome" />
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorDashboardChatMain;
