import React ,{useState, useEffect}from 'react'
import DashboardLayout from '../../Layouts/DashboardLayout'
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';
import "./DashboardBussinessPricingHistory.scss"
import axios from 'axios';
import { packageHistroyRoute } from '../../utils/ApiRoutes';
import {useAuth} from "../../context/auth"
const DashboardBussinessPricingHistory = () => {
  const [expanded, setExpanded] = useState(true);
    const [data, setData] = useState([])
    const [auth] = useAuth()
  const handleChange = (panel) => (isExpanded) => {
    if (expanded === "panel1") {
      setExpanded(false);
    } else {
      setExpanded(panel);
    }
  };
  const getData = async () => {
    try {
        const {data} = await axios.get(`${packageHistroyRoute}/${auth.user.code}`)
        if (data.error === false) {
            setData(data.plan)
        }
    } catch (error) {
        console.error(error.message);
    }
  }
  useEffect(() => {
    getData()
    // eslint-disable-next-line 
  }, [])
  

  return (
    <>
        <DashboardLayout>
        <div className="main-section">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h4>Package History </h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="table-data">
                  <div className="header-row">
                    <div className="heading">Plan Name</div>
                    <div className="heading">Date</div>
                    <div className="heading">Time</div>
                    <div className="heading">Expirey</div>
                    <div className='heading'>Status</div>
                  </div>
                  {data.map((item, i) => (
                    <div className="row" key={i}>
                      <div className="review-date">{parseInt(item.type) === 1 ? "Basic Plan" : parseInt(item.type) === 2 ? "Plus Plan": "Premium Plan"  }</div>
                      <div className="review-date">{moment(item.date).format("DD/MM/YYYY")}</div>
                      <div className="review-date">{item.time}</div>
                      <div className="review-date">{moment(item.expiry).format("DD/MM/YYYY")}</div>
                      <div className="review-date">{moment(item.expiry).isBefore(moment()) ? "Expired" : "Active" }</div>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </DashboardLayout>
    </>
  )
}

export default DashboardBussinessPricingHistory