import React, { useState ,useEffect} from "react";
import { RegionalSpec } from "../../assets/data";
import axios from "axios";
import { getAmenitiesRoute, updateAmenitiesRoute } from "../../utils/ApiRoutes";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";

import { useParams , useNavigate} from "react-router-dom"
import "./EditAmenities.scss"
const initialState = {
  amenity_id: null,
  product_id: null,
  fuel_type: "",
  body_condition: "",
  mechanical_condition: "",
  warranty: "",
  cylinders: "",
  transmission_type: "",
  body_type: "",
  horsepower: "",
  steering_side: "",
  trim: "",
  regional_spec: "",
  description: "",
  abs: 0,
  alloy_rims: 0,
  cassette_player: 0,
  climate_control: 0,
  front_camera: 0,
  keyless_entry: 0,
  power_mirrors: 0,
  rear_seat_entertainment: 0,
  rear_camera: 0,
  usb_and_auxillary_cable: 0,
  air_bags: 0,
  AM_FM_Radio: 0,
  cool_box: 0,
  dvd_player: 0,
  heated_seats: 0,
  navigation_system: 0,
  power_steering: 0,
  rear_ac_vents: 0,
  sun_roof: 0,
  air_conditioning: 0,
  ocd_player: 0,
  cruise_control: 0,
  front_speakers: 0,
  immobilizer_key: 0,
  power_locks: 0,
  power_windows: 0,
  rear_speakers: 0,
  steering_switches: 0,
  code: "",
};
const EditAmenities = () => {
  const {id:prodCode} = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [amenities, setAmenities] = useState(initialState);
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setAmenities({ ...amenities, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setAmenities({ ...amenities, [name]: checked ? 1 : 0 });
  };

  const validateForm = (amenities) => {
    const data = {
      fuel_type: "",
      body_condition: "",
      mechanical_condition: "",
      warranty: "",
      cylinders: "",
      transmission_type: "",
      body_type: "",
      horsepower: "",
      steering_side: "",
      trim: "",
      regional_spec: "",
    };
    const filterData = Object.keys(data).filter(key => amenities[key] === "" )
    if (filterData.length === 0 ) {
      return true
    }else{
      return false
    }
  };
  const handleFinishClick = async (e) => {
    e.preventDefault();
    setIsLoading(false);
    if (validateForm(amenities)) {
      const response = await axios.post(updateAmenitiesRoute, {
        ...amenities,
        code: prodCode,
      });
      if (response.data.error === false) {
        navigate(-1);
      }
    }else {
      toast.error("All Fields are required" ,toastOptions)
      console.log("All Fields are required");
    }
    setIsLoading(false);
  };


  useEffect(() => {
    getAmenities();
  }, []);

  const getAmenities = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${getAmenitiesRoute}/${prodCode}`);
      setAmenities(response.data.Amenities[0]);
    //   setValue(response.data.Amenities[0].description);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  return (
    <div className="center-area">
      {!isLoading && (
        <>
          <h1>Car Details Page</h1>
          <div className="car-detail-form">
            <form>
              <div className="inputs">
                <div>
                  <select
                    name="fuel_type"
                    id="fuel_type"
                    value={amenities.fuel_type}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Fuel Type</option>
                    <option value="1">Petrol</option>
                    <option value="2">Diesel</option>
                    <option value="3">Electric</option>
                    <option value="4">Hybrid</option>
                    <option value="5">LPG (Liquefied Petroleum Gas)</option>
                    <option value="6">CNG (Compressed Natural Gas)</option>
                    <option value="7">Hydrogen</option>
                  </select>
                </div>
                <div>
                  <select
                    name="body_condition"
                    id="body_condition"
                    value={amenities.body_condition}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Body Condtion</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
                <div>
                  <select
                    name="mechanical_condition"
                    id="mechanical_condition"
                    value={amenities.mechanical_condition}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Mechanical Condition</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>

                <div>
                  <select
                    name="warranty"
                    id="warranty"
                    value={amenities.warranty}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Warranty</option>
                    <option value="1">Yes</option>
                    <option value="2">No</option>
                  </select>
                </div>

                <div>
                  <select
                    name="cylinders"
                    id="cylinders"
                    value={amenities.cylinders}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Cylinders</option>
                    <option value="1">4 Cylinders</option>
                    <option value="2">6 Cylinders</option>
                    <option value="3">8 Cylinders</option>
                    <option value="4">Other</option>
                  </select>
                </div>
                <div>
                  <select
                    name="transmission_type"
                    id="transmission_type"
                    value={amenities.transmission_type}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Transmission Type</option>
                    <option value="1">Manual</option>
                    <option value="2">Automatic</option>
                  </select>
                </div>
                <div>
                  <select
                    name="body_type"
                    id="body_type"
                    value={amenities.body_type}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Body Type</option>
                    <option value="1">Sedan</option>
                    <option value="2">Coupe</option>
                    <option value="3">Hatchback</option>
                    <option value="4">Station Wagon</option>
                    <option value="5">SUV (Sport Utility Vehicle)</option>
                    <option value="6">Crossover</option>
                    <option value="7">Convertible</option>
                    <option value="8">Roadster</option>
                    <option value="9">Pick-up Truck</option>
                    <option value="10">Van</option>
                    <option value="11">MPV (Multi-Purpose Vehicle)</option>
                    <option value="12">Limousine</option>
                    <option value="13">Supercar</option>
                    <option value="14">Electric Vehicle (EV)</option>
                    <option value="15">Hybrid Car</option>
                  </select>
                </div>
                <div>
                  <select
                    name="horsepower"
                    id="horsepower"
                    value={amenities.horsepower}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Horspower</option>
                    <option value="1">Less than 100 HP</option>
                    <option value="2">100 - 200 HP</option>
                    <option value="3">200 - 300 HP</option>
                    <option value="4">300 - 400 HP</option>
                    <option value="5">400 - 500 HP</option>
                    <option value="6">500 - 600 HP</option>
                    <option value="7">600+ HP</option>
                  </select>
                </div>
                <div>
                  <select
                    name="steering_side"
                    id="steering_side"
                    value={amenities.steering_side}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Steering Side</option>
                    <option value="1">Left</option>
                    <option value="2">Right</option>
                  </select>
                </div>
                <div>
                  <select
                    name="trim"
                    id="trim"
                    value={amenities.trim}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0">Select Trim</option>
                    <option value="1">Base</option>
                    <option value="2">Premium</option>
                    <option value="3">Sport</option>
                    <option value="4">Luxury</option>
                    <option value="5">Limited</option>
                  </select>
                </div>
                <div>
                  <select
                    name="regional_spec"
                    id="regional_spec"
                    value={amenities.regional_spec}
                    onChange={(e) => handleSelectChange(e)}
                  >
                    <option value="0"> Select regional Spec</option>
                    {RegionalSpec?.map((data, i) =>
                      data === "" ? null : (
                        <option value={i} key={i}>
                          {data}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <div className="rich-text-editor"></div>
              <h1>Features</h1>
              <div className="checkboxes">
                <div>
                  <input
                    type="checkbox"
                    name="abs"
                    id="abs"
                    onChange={(e) => handleCheckboxChange(e)}
                    checked={amenities.abs === 0 ? false : true}
                  />
                  <label htmlFor="abs">ABS</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="alloy_rims"
                    onChange={(e) => handleCheckboxChange(e)}
                    id="alloy_rims"
                    checked={amenities.alloy_rims === 0 ? false : true}
                  />
                  <label htmlFor="alloy_rims">Alloy Rims</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="cassette_player"
                    onChange={(e) => handleCheckboxChange(e)}
                    id="cassette_player"
                    checked={amenities.cassette_player === 0 ? false : true}
                  />
                  <label htmlFor="cassette_player">Cassette Player</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="climate_control"
                    onChange={(e) => handleCheckboxChange(e)}
                    id="climate_control"
                    checked={amenities.climate_control === 0 ? false : true}
                  />
                  <label htmlFor="climate_control">Climate Control</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="front_camera"
                    onChange={(e) => handleCheckboxChange(e)}
                    id="front_camera"
                    checked={amenities.front_camera === 0 ? false : true}
                  />
                  <label htmlFor="front_camera">Front Camera</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="keyless_entry"
                    onChange={(e) => handleCheckboxChange(e)}
                    id="keyless_entry"
                    checked={amenities.keyless_entry === 0 ? false : true}
                  />
                  <label htmlFor="keyless_entry">Keyless Entry</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="power_mirrors"
                    onChange={(e) => handleCheckboxChange(e)}
                    id="power_mirrors"
                    checked={amenities.power_mirrors === 0 ? false : true}
                  />
                  <label htmlFor="power_mirrors">Power Mirrors</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="rear_seat_entertainment"
                    onChange={(e) => handleCheckboxChange(e)}
                    id="rear_seat_entertainment"
                    checked={
                      amenities.rear_seat_entertainment === 0 ? false : true
                    }
                  />
                  <label htmlFor="rear_seat_entertainment">
                    Rear Seat Entertainment
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    name="rear_camera"
                    onChange={(e) => handleCheckboxChange(e)}
                    id="rear_camera"
                    checked={amenities.rear_camera === 0 ? false : true}
                  />
                  <label htmlFor="rear_camera">Rear Camera</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="usb_and_auxillary_cable"
                    id="usb_and_auxillary_cable"
                    checked={
                      amenities.usb_and_auxillary_cable === 0 ? false : true
                    }
                  />
                  <label htmlFor="usb_and_auxillary_cable">
                    USB and Auxillary Cable
                  </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="air_bags"
                    id="air_bags"
                    checked={amenities.air_bags === 0 ? false : true}
                  />
                  <label htmlFor="air_bags">Air Bags</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="AM_FM_Radio"
                    id="AM_FM_Radio"
                    checked={amenities.AM_FM_Radio === 0 ? false : true}
                  />
                  <label htmlFor="AM_FM_Radio">AM/FM Radio</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="cool_box"
                    id="cool_box"
                    checked={amenities.cool_box === 0 ? false : true}
                  />
                  <label htmlFor="cool_box">Cool Box</label>
                </div>
                <div>
                  <input
                    onChange={(e) => handleCheckboxChange(e)}
                    type="checkbox"
                    name="dvd_player"
                    id="dvd_player"
                    checked={amenities.dvd_player === 0 ? false : true}
                  />
                  <label htmlFor="dvd_player">DVD Player</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="heated_seats"
                    id="heated_seats"
                    checked={amenities.heated_seats === 0 ? false : true}
                  />
                  <label htmlFor="heated_seats">Heated Seats</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="navigation_system"
                    id="navigation_system"
                    checked={amenities.navigation_system === 0 ? false : true}
                  />
                  <label htmlFor="navigation_system">Navigation System</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="power_steering"
                    id="power_steering"
                    checked={amenities.power_steering === 0 ? false : true}
                  />
                  <label htmlFor="power_steering">Power Steering</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="rear_ac_vents"
                    id="rear_ac_vents"
                    checked={amenities.rear_ac_vents === 0 ? false : true}
                  />
                  <label htmlFor="rear_ac_vents">Rear AC Vents</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="sun_roof"
                    id="sun_roof"
                    checked={amenities.sun_roof === 0 ? false : true}
                  />
                  <label htmlFor="sun_roof">Sun Roof</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="air_conditioning"
                    id="air_conditioning"
                    checked={amenities.air_conditioning === 0 ? false : true}
                  />
                  <label htmlFor="air_conditioning">Air Conditioning</label>
                </div>
                <div>
                  <input
                    onChange={(e) => handleCheckboxChange(e)}
                    type="checkbox"
                    name="ocd_player"
                    id="ocd_player"
                    checked={amenities.ocd_player === 0 ? false : true}
                  />
                  <label htmlFor="ocd_player"> OCD Player</label>
                </div>
                <div>
                  <input
                    onChange={(e) => handleCheckboxChange(e)}
                    type="checkbox"
                    name="cruise_control"
                    id="cruise_control"
                    checked={amenities.cruise_control === 0 ? false : true}
                  />
                  <label htmlFor="cruise_control">Cruise Control</label>
                </div>
                <div>
                  <input
                    onChange={(e) => handleCheckboxChange(e)}
                    type="checkbox"
                    name="front_speakers"
                    id="front_speakers"
                    checked={amenities.front_speakers === 0 ? false : true}
                  />
                  <label htmlFor="front_speakers">Front Speakers</label>
                </div>
                <div>
                  <input
                    onChange={(e) => handleCheckboxChange(e)}
                    type="checkbox"
                    name="immobilizer_key"
                    id="immobilizer_key"
                    checked={amenities.immobilizer_key === 0 ? false : true}
                  />
                  <label htmlFor="immobilizer_key">Immobilizer Key</label>
                </div>
                <div>
                  <input
                    onChange={(e) => handleCheckboxChange(e)}
                    type="checkbox"
                    name="power_locks"
                    id="power_locks"
                    checked={amenities.power_locks === 0 ? false : true}
                  />
                  <label htmlFor="power_locks">Power Locks</label>
                </div>
                <div>
                  <input
                    onChange={(e) => handleCheckboxChange(e)}
                    type="checkbox"
                    name="power_windows"
                    id="power_windows"
                    checked={amenities.power_windows === 0 ? false : true}
                  />
                  <label htmlFor="power_windows">Power Windows</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="rear_speakers"
                    id="rear_speakers"
                    checked={amenities.rear_speakers === 0 ? false : true}
                  />
                  <label htmlFor="rear_speakers">Rear Speakers</label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxChange(e)}
                    name="steering_switches"
                    id="steering_switches"
                    checked={amenities.steering_switches === 0 ? false : true}
                  />
                  <label htmlFor="steering_switches">Steering Switches</label>
                </div>
              </div>
            </form>
          </div>
          <div className="btn-section stick-bottom">
            <button onClick={(e) => handleFinishClick(e)}>Submit</button>
          </div>
        </>
      )}
      {isLoading && (
        <div className="center1">
          <div className="car-loader"></div>
        </div>
      )}
    </div>
  );
};

export default EditAmenities;
