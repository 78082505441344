import React, { useCallback, useState } from "react";
import "./PricingCard.scss";
import { useAuth } from "../../context/auth";
import axios from "axios";
import { AcitivatePlan } from "../../utils/ApiRoutes";
import { ImCheckmark } from "react-icons/im";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/ToastOptions";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
const PricingCard = ({ icon, title, id, description, price, features }) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [seeMore, setSeeMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const activatePlan = useCallback(
    async (e) => {
      setIsLoading(true);
      e.preventDefault();
      try {
        if (auth.user && auth.userType === 1) {
          const { data } = await axios.post(AcitivatePlan, {
            type: id,
            code: auth.user.code,
          });
          if (data.error === false) {
            toast.success(data.message, toastOptions);
            setAuth((prev) => ({
              ...prev,
              user: {
                ...prev.user,
                planType: id,
              },
            }));
            const authData = JSON.parse(localStorage.getItem("auth"));
            authData.user.planType = id;
            localStorage.setItem("auth", JSON.stringify(authData));
            navigate(`/dashboard/business-profile/${auth.user.url}`);
          } else {
            toast.error(data.message, toastOptions);
          }
        } else {
          toast.error("This Feature is only Available for User", toastOptions);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [auth.user, auth.userType, id, setAuth, navigate]
  );

  return (
    <div className="pricing-card-main">
      <div className="pricing-card-header">
        <div>{icon}</div>
        <h4>{title}</h4>
      </div>
      <p>{description}</p>
      <div className="monthly-price">
        <h3>
          <span>${price}</span> /monthly
        </h3>
      </div>
      <div className="pricing-features">
        {features
          ?.slice(0, seeMore ? features?.length : 4)
          ?.map((feature, i) => (
            <div className="pricing-feature" key={i}>
              <div className="pricing-check-icon">
                <ImCheckmark />
              </div>
              <h5>{feature}</h5>
            </div>
          ))}
      </div>
      {features?.length > 4 && (
        <span className="see-more" onClick={() => setSeeMore((prev) => !prev)}>
          See more
        </span>
      )}
      {auth.user && auth.userType === 1 && auth.user.planType ? (
        <button onClick={activatePlan} disabled={isLoading}>
          {id === auth.user.planType ? "Active" : "Not Active"}
        </button>
      ) : (
        <button onClick={activatePlan} disabled={isLoading}>
          {auth.user && auth.userType !== 1 && isLoading ? (
            <CircularProgress size={22} color="inherit" />
          ) : (
            "Get Started"
          )}
        </button>
      )}
    </div>
  );
};

export default PricingCard;
